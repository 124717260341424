import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import axios from "axios";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import CommonLoader from "../../../loader/CommonLoader";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Alert from "react-bootstrap/Alert";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../App";
import { hasPermission } from "../../../helperPermissionEditor";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import CustomModal from "../common-components/customModal";
import { ImAttachment } from "react-icons/im";
import { MultiSelect } from "react-multi-select-component";
import DeleteModal from "../common-components/deleteModal";
import NoDataFoundPage from "../common-components/NoDataFound";
import { FaTiktok } from "react-icons/fa";
import GlobalFilter from "./component/GlobalFilter";
import { Badge } from "react-bootstrap";

const FreelancersListing = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [freelancers, setFreelancers] = useState([]),
    [isEditing, setIsEditing] = useState(""),
    [isAttach, setIsAttach] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [editedStatus, setEditedStatus] = useState(""),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [statusOptions, setStatusOption] = useState([]),
    [selectedOptions, setSelectedOptions] = useState([]),
    [newFreelancer, setNewFreelancer] = useState({
      name: "",
      phone: "",
      address: "",
      email: "",
      password: "",
    }),
    [type, setType] = useState({
      visible: false,
      data: [],
    }),
    [show, setShow] = useState(false),
    [selectedFreelancer, setSelectedFreelancer] = useState(""),
    [options, setOptions] = useState({
      clients: [],
    }),
    [tikTokOptions, setTikTokOptions] = useState({
      tiktoks: [],
    }),
    [selectedRecord, setSelectedRecord] = useState({
      id: "",
      modal: false,
    }),
    [deleteClient, setDeleteClient] = useState(false),
    [selectedClientID, setSelectedClientID] = useState(""),
    [perPage, setPerPage] = useState(10),
    role = localStorage.getItem("userRole"),
    hasAccountManagerRole = role === "account-manager",
    hasAdminRole = role === "super-admin" || role === "admin",
    [filters, setFilters] = useState({
      search: "",
      email: "",
      date_from: "",
      date_to: "",
      status: "",
    }),
    [filterModal, setFilterModal] = useState(false);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  const handleClose = () => {
    setShow(false);
    setNewFreelancer({
      name: "",
      phone: "",
      address: "",
      email: "",
      password: "",
    });
    setEditedStatus("");
    setIsEditing("");
    setSelectedFreelancer("");
    setSelectedOptions([]);
    setType({
      visible: false,
      data: [],
    });
    setIsAttach("");
    setFilterModal(false);
  };
  const navigate = useNavigate();

  const fetchFreelancers = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      if (filters) {
        setFilterModal(false);
      }
      const params = new URLSearchParams({
        page,
        ...filters,
        status: String(filters.status)  // Explicitly convert status to string
      }).toString();
      const response = await axiosInstance.get(
        `/api/freelancers?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFreelancers(response.data.data);
      setTotalPages(response.data.pagination.last_page);
      setPerPage(response.data.pagination.per_page);
    } catch (error) {
      console.error("Error fetching freelancers:", error);
    }
    setIsLoading(false);
  };
  const getClientOptions = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/dashboard/clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // const data = response.data.clients;
        const data = response.data.clients.map((client) => ({
          label: client.name,
          value: client.id,
          id: client.id,
        }));

        setOptions({
          clients: data,
        });
      }
    } catch (error) {
      console.error("Error fetching freelancers:", error);
    }
    setIsLoading(false);
  };

  const getTikToksOptions = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/dashboard/tiktoks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const data = response.data.tiktoks.map((tiktok) => ({
          label: tiktok.tiktok,
          value: tiktok.tiktok,
          id: tiktok.id,
          client_id: tiktok.client_id,
        }));

        setTikTokOptions({
          tiktoks: data,
        });
      }
    } catch (error) {
      console.error("Error fetching freelancers:", error);
    }
    setIsLoading(false);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  useEffect(() => {
    fetchFreelancers(currentPage);
    getClientOptions();
    getTikToksOptions();
    handleShow();
    const intervalId = setInterval(() => {
      fetchFreelancers();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [currentPage, filters]);

  const handleEdit = async (record) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(
        `/api/freelancers/${record.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setIsLoading(false);

        setShow(true);
        const {
          name,
          phone,
          address,
          user: { email },
        } = response.data.freelancer;

        setIsEditing(response.data.freelancer);

        setNewFreelancer((prevState) => ({
          ...prevState,
          name,
          phone,
          address,
          email,
        }));
        setEditedStatus(response.data.freelancer.status);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching freelancer for edit:", error);
    }
  };

  const handleShow = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(`/api/freelancers/create`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const createOptions = Object.values(response?.data[`status-dropdown`]);
        const localOptions = [
          { id: 0, name: createOptions[0] },
          { id: 1, name: createOptions[1] },
        ];

        setStatusOption(localOptions);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.message || "Error creating freelancer");
      }
    }
  };

  const deleteRecordAPICall = async (freelancerId) => {
    const token = localStorage.getItem("token");

    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/freelancers/${freelancerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFreelancers(
        freelancers.filter((freelancer) => freelancer.id !== freelancerId)
      );
      toast.success("Freelancer deleted successfully!");
      setSelectedRecord({
        id: "",
        modal: false,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        navigate("/");
        window.location.reload();
      } else {
        console.error("Error deleting freelancer:", error);
        toast.error("Error deleting freelancer");
      }
    }
    setIsLoading(false);
  };

  const handleUpdateFreelancer = async () => {
    const token = localStorage.getItem("token");

    const updatedFreelancerData = {
      name: newFreelancer.name,
      phone: Number(newFreelancer.phone),
      address: newFreelancer.address,
      email: newFreelancer.email || "", // Access email from the user object
      status: Number(editedStatus),
      user_id: isEditing.user?.id || null,
    };

    try {
      const response = await axiosInstance.put(
        `/api/freelancers/${isEditing.id}`,
        updatedFreelancerData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleClose();
      toast.success("Freelancer updated successfully");
      fetchFreelancers();
      if (response.status === 201) {
        setIsEditing("");
      } else {
        console.error("Error updating freelancer:", response);
      }
    } catch (error) {
      console.error("Error updating freelancer:", error);
      toast.error("Please try again");
    }
  };

  const handleCreateFreelancer = async () => {
    const { name, phone, address, email, password } = newFreelancer;

    // Validate the form fields
    if (!name || !phone || !address || !email || !password) {
      toast.error("Please fill in all the fields before submitting.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const freelancerPayload = {
      name,
      phone: Number(phone),
      address,
      email,
      password,
    };

    try {
      await axiosInstance.post(`/api/freelancers`, freelancerPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      fetchFreelancers();
      setNewFreelancer({
        name: "",
        phone: "",
        address: "",
        email: "",
        password: "",
      });
      toast.success("Freelancer created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error creating freelancer");
      console.error("Error creating freelancer:", error);
    }
  };

  const getAttachedClients = async (row) => {
    setSelectedFreelancer(row);
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/api/freelancers/${row?.id}/clients`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { clients } = response?.data;

      if (response.status === 200) {
        setIsAttach("clients");
        const data = Object.values(clients);
        setType({
          visible: true,
          data,
        });
        const activeRecord = data
          .map((r) => {
            if (r.status === "Active") {
              return {
                id: r.id,
                label: r.name,
                value: r.client_id,
              };
            }
            return null;
          })
          .filter(Boolean);

        setSelectedOptions(activeRecord);

        setShow(true);
      }
    } catch (error) {
      setIsAttach("");
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  const getAttachedTiktokAccounts = async (row) => {
    setSelectedFreelancer(row);
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/api/freelancers/${row?.id}/tiktoks`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { tiktoks } = response?.data;

      if (response.status === 200) {
        setIsAttach("tiktoks");
        const data = Object.values(tiktoks);

        setType({
          visible: true,
          data,
        });
        const activeRecord = data
          .map((r) => {
            if (r.status === "Active") {
              return {
                id: r.id,
                label: r.name,
                value: r.id,
                client_id: r.client_id !== undefined ? r.client_id : "",
              };
            }
            return null;
          })
          .filter(Boolean);

        setSelectedOptions(activeRecord);
        setShow(true);
      }
    } catch (error) {
      setIsAttach("");
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  const handleClientAttachment = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const list = selectedOptions.map((selected) => selected.id);
    const activeIds = type.data
      .filter((record) => record.status === "Active" && record.id)
      .map((r) => r.id);

    let payload = {
      freelancer_id: selectedFreelancer?.id,
      clients: [...list]
    };

    try {
      await axiosInstance.post(`/api/freelancers/attach-clients`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      fetchFreelancers();
      toast.success("Record created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error creating record");
      console.error("Error creating record:", error);
    }
  };

  const handleTikTokAttachment = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    const tikTokAccounts = selectedOptions.map((selected) => ({
      tiktok_id: selected.id,
      client_id: selected.client_id,
    }));

    const activeIds = type.data
      .filter((record) => record.status === "Active" && record.id)
      .map((r) => r.id);

    let payload = {
      freelancer_id: selectedFreelancer?.id,
      tik_tok_accounts: tikTokAccounts,
    };

    try {
      await axiosInstance.post(`/api/freelancers/attach-clients`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      fetchFreelancers();
      toast.success("Record created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response?.data?.message || "Error creating record");
      console.error("Error creating record:", error);
    }
  };

  const filteredFreelancers = freelancers.filter((freelancer) =>
    freelancer.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleClientDeattach = async (freelancerClient) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      await axiosInstance.post(
        `/api/freelancers/deattach-client`,
        {
          freelancer_client: freelancerClient,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Client detached successfully!");
      setDeleteClient(false);
      setShow(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error detaching client");
    } finally {
      setIsLoading(false);
    }
  };

  const getTableUI = () => {
    return (
      <table className="user-table mt-4">
        <thead>
          <tr>
            <th>SR #.</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Email</th>
            <th>Created By</th>
            <th>Created At</th>
            <th>Attach Clients</th>
            <th>Attach TikTok Accounts</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredFreelancers?.length > 0 ? (
            filteredFreelancers?.map((freelancer, index) => (
              <tr key={freelancer.id}>
                <td>{(currentPage - 1) * perPage + index + 1}</td>
                <td>{freelancer.name}</td>
                <td>{freelancer.phone}</td>
                <td>{freelancer.address}</td>
                <td>{freelancer.email}</td>
                <td>
                  {freelancer.created_by}<br />
                  <small>
                    <Badge
                      bg="primary"
                    >
                      {freelancer.created_by_role}
                    </Badge>
                  </small>
                </td>
                <td>{freelancer.created_at}</td>
                <td>
                  <Toaster position="top-center" richColors />
                  <button
                    data-tooltip-id="attach-client"
                    className="edit me-2"
                    onClick={() => getAttachedClients(freelancer)}
                    style={{
                      padding: "4px 10px",
                      fontSize: "22px",
                    }}
                    disabled={!hasPermission(user?.permissions, "attach-client")}
                  >
                    <ImAttachment />
                  </button>
                  <ReactTooltip
                    id="attach-client"
                    place="bottom"
                    content="Attach Clients"
                  />
                </td>

                <td>
                  <Toaster position="top-center" richColors />
                  <button
                    data-tooltip-id="attach-tiktok-accounts"
                    className="edit me-2"
                    onClick={() => getAttachedTiktokAccounts(freelancer)}
                    style={{
                      padding: "4px 10px",
                      fontSize: "22px",
                    }}
                    disabled={!hasPermission(user?.permissions, "attach-tik-tok")}
                  >
                    <FaTiktok />
                  </button>
                  <ReactTooltip
                    id="attach-tiktok-accounts"
                    place="bottom"
                    content="Attach TikTok Accounts"
                  />
                </td>

                <td className="d-flex align-items-center justify-content-center">
                  <span
                    className="px-2 edit me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <TbEdit
                      size={24}
                      data-tooltip-id="editTooltip"
                      data-tooltip-content="Edit"
                      onClick={() => handleEdit(freelancer)}
                      disabled={
                        !hasPermission(user?.permissions, "update-freelancer")
                      }
                    />
                    <ReactTooltip id="editTooltip" />
                  </span>

                  <span
                    className="px-2"
                    style={{
                      cursor: "pointer",
                      background: "red",
                      color: "white",
                      fontSize: "16px",
                      padding: "8px 10px",
                      borderRadius: "8px",
                    }}
                  >
                    <MdDelete
                      size={24}
                      data-tooltip-id="deleteTooltip"
                      data-tooltip-content="Delete"
                      onClick={() => {
                        setSelectedRecord({
                          id: freelancer.id,
                          modal: true,
                        });
                      }}
                      disabled={
                        !hasPermission(user?.permissions, "delete-freelancer")
                      }
                    />
                    <ReactTooltip id="deleteTooltip" />
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                <NoDataFoundPage />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const handleChange = (value) => {
    if (value?.length < 1) {
      setSelectedOptions([]);
    }
    setSelectedOptions(value);
  };
  const showListing = () => {
    return (
      <>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {type?.data?.length > 0 ? (
              type?.data?.map((row, i) => (
                <tr key={row.id}>
                  <td>{i + 1}</td>
                  <td>{row.name}</td>
                  <td>{row.status}</td>
                  <td>
                    <button
                      data-tooltip-id="Delete-button"
                      className="delete edit"
                      style={{
                        background: "red",
                        color: "white",
                        fontSize: "22px",
                        padding: "4px 10px",
                      }}
                      onClick={() => {
                        setDeleteClient(true);
                        setSelectedClientID(row.id);
                      }}
                      disabled={row?.deleted_at ? 'disabled' : ''}
                    >
                      <MdDelete />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No Records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <h5> Select {isAttach === "clients" ? "Clients" : "TikTok Accounts"} to Attach</h5>

        {isAttach === "clients" && (
          <MultiSelect
            options={options?.clients}
            value={selectedOptions}
            onChange={handleChange}
            labelledBy="Select"
            className="multi-select-container"
          />
        )}

        {isAttach === "tiktoks" && (
          <MultiSelect
            options={tikTokOptions?.tiktoks}
            value={selectedOptions}
            onChange={handleChange}
            labelledBy="Select"
            className="multi-select-container"
          />
        )}
      </>
    );
  };

  const getFormUI = () => {
    return type?.visible ? (
      showListing()
    ) : (
      <div style={{ justifyContent: "center !important" }}>
        <Form.Group className="mb-3" controlId="formFreelancerName">
          <Form.Control
            type="text"
            placeholder="Enter freelancer name"
            value={newFreelancer.name}
            onChange={(e) =>
              setNewFreelancer({ ...newFreelancer, name: e.target.value })
            }
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formFreelancerPhone">
          <Form.Control
            type="text"
            placeholder="Enter phone number"
            value={newFreelancer.phone}
            onChange={(e) =>
              setNewFreelancer({ ...newFreelancer, phone: e.target.value })
            }
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formFreelancerAddress">
          <Form.Control
            type="text"
            placeholder="Enter address"
            value={newFreelancer.address}
            onChange={(e) =>
              setNewFreelancer({
                ...newFreelancer,
                address: e.target.value,
              })
            }
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formFreelancerEmail">
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={newFreelancer.email}
            onChange={(e) =>
              setNewFreelancer({ ...newFreelancer, email: e.target.value })
            }
          />
        </Form.Group>
        {!isEditing && (
          <Form.Group className="mb-3" controlId="formFreelancerPassword">
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={newFreelancer.password}
              onChange={(e) =>
                setNewFreelancer({
                  ...newFreelancer,
                  password: e.target.value,
                })
              }
            />
          </Form.Group>
        )}

        {isEditing && (
          <Form.Group>
            <select
              name="status"
              className="rolelistingDropdown creatuserListing form-select"
              value={editedStatus}
              onChange={(e) => setEditedStatus(e.target.value)}
            >
              <option disabled value="">
                Select Status
              </option>
              {statusOptions.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </Form.Group>
        )}
      </div>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Freelancer"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => {
          setShow(true);
        }}
        user={user}
      />
      <Button
        variant="primary"
        onClick={() => {
          setFilterModal(true);
        }}
        className="mt-3"
      >
        Filter
      </Button>
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
          {totalPages > 1 && (
            <div className="pagination">
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                pageStart={pageStart}
              />
            </div>
          )}
        </div>
      </div>
      {/* Delete Modal */}
      <DeleteModal
        toggleState={selectedRecord?.modal}
        toggleHandler={() => setSelectedRecord(false)}
        apiCallHandleronClick={() => deleteRecordAPICall(selectedRecord?.id)}
      />
      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <div>
            {type?.visible ? (
              <h2 className="create_user_heading">Attached {isAttach == "clients" ? "Clients" : "TikTok Accounts"} </h2>
            ) : (
              <>
                <h2 className="create_user_heading">{`${isEditing ? "Update" : "Create"
                  } Freelancer`}</h2>
                <p className=" fs-5 mt-3 text-muted">
                  {`${isEditing ? "Update" : "Create"
                    } a Freelancer to manage and view your profile`}
                </p>
              </>
            )}
          </div>
        }
        bodyContent={getFormUI()}
        footerContent={
          <>
            <div className="d-flex justify-content-center align-items-center">
              {type?.visible ? (
                <Button
                  variant="primary"
                  className="creatrUserSubmit me-3"
                  onClick={isAttach == "clients" ? handleClientAttachment : handleTikTokAttachment}
                >
                  Attach {isAttach == "clients" ? "Clients" : "TikTok Accounts"}
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="creatrUserSubmit me-3"
                  onClick={
                    isEditing ? handleUpdateFreelancer : handleCreateFreelancer
                  }
                  disabled={isLoading}
                >
                  {`${isEditing ? "Update" : "Create"} Freelancer`}
                </Button>
              )}

              <Button
                variant="secondary"
                className="creatrUserClose"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
      <CustomModal
        show={deleteClient}
        handleClose={() => {
          setDeleteClient(false);
        }}
        headerContent={<h4>Confirm Delete</h4>}
        bodyContent={
          <p>
            Are you sure you want to detach this{" "}
            {isAttach === "clients" ? "client" : "tiktok account"}?
          </p>
        }
        footerContent={
          <>
            <Button
              variant="danger"
              onClick={() =>
                handleClientDeattach(selectedClientID)
              }
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Confirm"}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setDeleteClient(false);
              }}
            >
              Close
            </Button>
          </>
        }
      />
      {/* Filters Modal */}
      <CustomModal
        show={filterModal}
        handleClose={() => setFilterModal(false)}
        headerContent={<h4>Filters</h4>}
        bodyContent={<GlobalFilter parentFilters={filters} accountManagers={null} onFilterChange={handleFilterChange} isAdmin={hasAdminRole} module={"freelancers"} />}
        footerContent={<></>}
      />
    </div>
  );
};

export default FreelancersListing;
