import React, { useState, useContext, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ProgressBar from "./navbar/ProgressBar";
import { hasPermission } from "../../helperPermissionEditor";
import { LoginContext } from "../../App";
import "./dashboard.css";
// components import
import DashboardListing from "./pages/DashboardListing";
import UserListing from "./pages/UserListing";
import RolesListing from "./pages/RolesListing";
import PermissionListing from "./pages/PermissionListing";
import ModelRoleListingPermission from "./pages/roleListingModel/ModelRoleListingPermission";
import ClientListing from "./pages/ClientListing";
import AccountManager from "./pages/AccountManager";
import Header from "./navbar/header/Header";
import FreelancersListing from "./pages/FreelancersListing";
import PhoneListing from "./pages/PhoneListing";
import CompanyManagement from "./pages/CompanyManagement.jsx";
import LabelListing from "./pages/LabelListing";
import SoundListing from "./pages/SoundListing";
import BookListing from "./pages/BookListing.jsx";
import TextListing from "./pages/TextListing.jsx";
import HashTagListing from "./pages/HashTagListing.jsx";
import TikTokListing from "./pages/TikTokListing.jsx";
import TikTokVideosListing from "./pages/TikTokVideosListing.jsx";
import TikTokUserDetails from "./pages/TikTokUserDetails";
import TikTokUserVideosDetails from "./pages/TikTokUserVideosDetails";
import TikTokVideoDetails from "../Dashboard/pages/TikTokVideoDetails";
import TikTokVideoSoundDetails from "../Dashboard/pages/TikTokVideoSoundDetails";
import TikTokUserStatistics from "./pages/TikTokUserStatistics.jsx";
import TikTokVideoStatistics from "./pages/TikTokVideoStatistics.jsx";
import FreelancerClientsListing from "./pages/FreelancerClient/index.jsx";
import FreelancerClientsBooks from "./pages/FreelancerClient/Books.jsx";
import FreelancerClientsText from "./pages/FreelancerClient/Text.jsx";
import FreelancerClientsHashtag from "./pages/FreelancerClient/Hashtag.jsx";
import DailyAPIHits from "./pages/DailyAPIHits.jsx";
import TikTokSoundsListing from "./pages/TikTokSoundsListing.jsx";
import TikTokSoundStatistics from "./pages/TikTokSoundStatistics.jsx";
import Tags from "./pages/Tags.jsx";
import AddTiktokAccounts from "./pages/AddTiktokAccounts.jsx";
import MainCards from "./common-components/main-cards/index.jsx";
import ExternalTikTokSoundsListing from "./pages/ExternalTikTokSoundsListing.jsx";
import { initializeTokenRefresh } from "../../refershTokenManage/axiosConfig.js";
import HandleTikTokRedirect from "./pages/HandleTikTokRedirect.jsx";

const DashboardIndex = () => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { user } = useContext(LoginContext);
  const location = useLocation();
  const from = location.state?.from || "Unknown";

  const handleSelectUser = (userId) => setSelectedUserId(userId);
  useEffect(() => {
    if (localStorage.getItem("token") && from !== "signIn") {
      initializeTokenRefresh();
    }
  }, []);

  return (
    <div className="dashboard-container">
      <ProgressBar />
      <div className="dashboard content">
        <Header />
        <div class="userLists">
          <MainCards />
        </div>
        <Routes>
          <Route path="/" exact element={<Navigate to="/dashboard" />} />

          <Route path="/dashboard" element={<DashboardListing />} />

          <Route path="/auth/tiktok/callback" element={<HandleTikTokRedirect />} />

          {hasPermission(user.permissions, "view-user") && (
            <Route path="/userListing" element={<UserListing />} />
          )}

          {hasPermission(user.permissions, "view-role") && (
            <Route
              path="/rolelist"
              element={<RolesListing onUserSelect={handleSelectUser} />}
            />
          )}

          {hasPermission(user.permissions, "view-permission") && (
            <Route path="/permissionlist" element={<PermissionListing />} />
          )}

          {hasPermission(user.permissions, "view-client") && (
            <Route path="/clientListing" element={<ClientListing />} />
          )}

          {hasPermission(user.permissions, "view-account-manager") && (
            <Route path="/accountManager" element={<AccountManager />} />
          )}

          {hasPermission(user.permissions, "view-role") && (
            <Route
              path="/rolelist"
              element={<RolesListing onUserSelect={handleSelectUser} />}
            />
          )}

          {hasPermission(user.permissions, "view-permission") && (
            <Route path="/permissionlist" element={<PermissionListing />} />
          )}

          {hasPermission(user.permissions, "view-role") && (
            <Route
              path="/modelListing"
              element={<ModelRoleListingPermission userId={selectedUserId} />}
            />
          )}

          {hasPermission(user.permissions, "view-freelancer") && (
            <Route
              path="/FreelancersListing"
              element={<FreelancersListing />}
            />
          )}

          {hasPermission(user.permissions, "view-phone") && (
            <Route path="/PhoneListing" element={<PhoneListing />} />
          )}

          {hasPermission(user.permissions, "view-label") && (
            <Route path="/labels" element={<LabelListing />} />
          )}

          {hasPermission(user.permissions, "view-sound") && (
            <Route path="/sounds" element={<SoundListing />} />
          )}

          {hasPermission(user.permissions, "view-book") && (
            <Route path="/books" element={<BookListing />} />
          )}

          {hasPermission(user.permissions, "view-text") && (
            <Route path="/texts" element={<TextListing />} />
          )}

          {hasPermission(user.permissions, "view-company") && (
            <Route path="/company" element={<CompanyManagement />} />
          )}

          {hasPermission(user.permissions, "view-hashtag") && (
            <Route path="/hashTags" element={<HashTagListing />} />
          )}
          {/* {hasPermission(user.permissions, "view-tag") && ( */}
          <Route path="/tags" element={<Tags />} />
          {/* )} */}

          {localStorage.getItem("userRole") === "client" && (
            <Route path="/add-tiktoks" element={<AddTiktokAccounts />} />
          )}

          {/* Open routes */}
          <Route path="/tiktokAccounts" element={<TikTokListing />} />
          <Route path="/tiktokVideos" element={<TikTokVideosListing />} />
          <Route
            path="/Tiktok-statistics/:tiktokId"
            element={<TikTokUserStatistics />}
            exact
          />
          <Route
            path="/Tiktok-video-statistics/:videoId"
            element={<TikTokVideoStatistics />}
            exact
          />
          <Route
            path="/freelancerClients"
            element={<FreelancerClientsListing />}
            exact
          />
          <Route
            path="/showBooks/:id"
            element={<FreelancerClientsBooks />}
            exact
          />
          <Route
            path="/showText/:id"
            element={<FreelancerClientsText />}
            exact
          />
          <Route
            path="/showHashTag/:source/:id"
            element={<FreelancerClientsHashtag />}
            exact
          />

          <Route
            path="/Tiktok-UserDetails/:tiktokId"
            element={<TikTokUserDetails />}
          />
          <Route
            path="/Tiktok-UsersDetails/:tiktokId/videos"
            element={<TikTokUserVideosDetails />}
            exact
          />
          <Route
            path="/Tiktok-video/:videoId"
            element={<TikTokVideoDetails />}
            exact
          />
          <Route
            path="/Tiktok-video/sound/:soundId"
            element={<TikTokVideoSoundDetails />}
            exact
          />
          <Route
            path="/tiktok-sounds"
            element={<TikTokSoundsListing />}
            exact
          />
          <Route
            path="/external-tiktok-sounds"
            element={<ExternalTikTokSoundsListing />}
            exact
          />
          <Route
            path="/Tiktok-sound-statistics/:soundId"
            element={<TikTokSoundStatistics />}
            exact
          />

          {localStorage.getItem("userRole") === "super-admin" && (
            <Route path="/totalApiHits" element={<DailyAPIHits />} exact />
          )}

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default DashboardIndex;
