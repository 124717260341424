import React, { useEffect, useState, useContext, useMemo } from "react";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import DaysFilter from "./component/DaysFilter";
import moment from "moment";
import subDays from "date-fns/subDays";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ImQuestion } from "react-icons/im";
import { FaExternalLinkAlt, FaLink } from "react-icons/fa";
import MainCards from "../common-components/main-cards";
import NoDataFoundPage from "../common-components/NoDataFound";

const TikTokSoundsListing = () => {
  const [sounds, setSounds] = useState([]),
    [selectedDays, setSelectedDays] = useState([
      subDays(new Date(), 30 - 1),
      new Date(),
    ]),
    [filterSounds, setFilterSounds] = useState(0),
    [isLoading, setIsLoading] = useState(false),
    [error, setError] = useState(null),
    [pagination, setPagination] = useState([]),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    role = localStorage.getItem("userRole"),
    hasAdminRole = role === "super-admin" || role === "admin",
    [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const fetchSound = async (page = 1) => {
    setIsLoading(true);
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");
    const token = localStorage.getItem("token");
    const label = "0";
    try {
      const response = await axiosInstance.post(
        `/api/tiktok/sounds?page=${page}`,
        {
          days: null,
          from: from,
          to: to,
          label: label,
          filter: filterSounds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { data, pagination: apiPagination } = response.data;
      if (!apiPagination) {
        console.error("Pagination data missing from the response");
      }
      const reArrange = data.map((i) => ({
        current_videos: i.current_videos,
        id: i.id,
        share_url: i.share_url,
        sound_id: i.sound_id,
        starred: i.starred,
        ...reArrangeObject(i.days),
      }));

      setSounds(reArrange);
      setPagination(apiPagination);
      setCurrentPage(apiPagination.current_page);
      setTotalPages(apiPagination.last_page);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSound(currentPage);
  }, [currentPage, selectedDays, filterSounds]);

  function reArrangeObject(data) {
    if (!typeof data === "object") {
      console.error(
        "days are not an object which are manditory for process sorting"
      );
      return;
    }

    let makeObject = {};
    Object.values(data).forEach((d, i) => {
      makeObject = {
        ...makeObject,
        [`d_${i + 1}_videos_increased`]: d.videos_increased,
        [`d_${i + 1}_variation`]: d.variation,
        [`d_${i + 1}_color`]: d.color,
      };
    });
    return makeObject;
  }

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const sortedSoundsListing = useMemo(() => {
    if (!sortConfig.key) return sounds;
    const sortedData = [...sounds];
    sortedData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  }, [sounds, sortConfig]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return null;
  };

  const handleStarred = async (event, id) => {
    const starred = event.target.checked ? 1 : 0;
    const token = localStorage.getItem("token");
    axiosInstance
      .post(
        `/api/tiktok/attach-starred`,
        {
          sound_id: id,
          starred: starred,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        fetchSound();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader dashboardName={"TikTok Sounds"} />
      <p
        style={{
          color: "red",
          fontSize: "12px",
          marginBottom: "15px",
          marginTop: "15px",
          fontWeight: "bold",
        }}
      >
        Sounds will start to populate after accumulating at least 7 days of data
        scraped from your tracked accounts.
      </p>
      <div>
        <DaysFilter selectedDays={selectedDays} onChange={onChange} />
        {hasAdminRole && (
          <div>
            <label>
              <input
                style={{ verticalAlign: "middle", marginRight: "5px" }}
                type="checkbox"
                checked={filterSounds}
                onChange={(e) => setFilterSounds(e.target.checked ? 1 : 0)}
              />
              <small><b>Super Admin Client Sounds</b></small>
            </label>
          </div>
        )}
      </div>
      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">
            <table className="user-table mt-4">
              <thead>
                <tr>
                  <th rowSpan="2">ID</th>
                  <th rowSpan="2">Starred</th>
                  <th rowSpan="2">URL</th>
                  <th
                    rowSpan="2"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSort("current_videos")}
                  >
                    Current # of Videos &nbsp;
                    {getArrow("current_videos")}
                  </th>
                  {/* Top level header for Days */}
                  {Array.from({ length: 7 }, (_, i) => (
                    <th
                      className="text-center font-size-11"
                      key={i}
                      colSpan="2"
                    >
                      Day {i + 1}
                    </th>
                  ))}
                </tr>
                <tr>
                  {/* Sub-columns under each Day */}
                  {Array.from({ length: 7 }, (_, i) => (
                    <React.Fragment key={i}>
                      <th
                        className="font-size-11"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleSort(`d_${i + 1}_videos_increased`)
                        }
                      >
                        Videos Increased &nbsp;
                        {getArrow(`d_${i + 1}_videos_increased`)}
                      </th>
                      <th
                        className="font-size-11"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort(`d_${i + 1}_variation`)}
                      >
                        Variation % &nbsp;
                        {getArrow(`d_${i + 1}_variation`)}
                      </th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedSoundsListing.length > 0 ? (
                  sortedSoundsListing.map((sound, index) => (
                    <tr key={index}>
                      <td>
                        <Link
                          to={`/Tiktok-sound-statistics/${sound?.sound_id}`}
                        >
                          <span data-tooltip-id="noedit-button">
                            <FaExternalLinkAlt />
                          </span>
                          <ReactTooltip
                            id="noedit-button"
                            place="bottom"
                            content="go to sound statistics"
                          />
                        </Link>
                      </td>
                      <td className="text-center">
                        <Form.Check
                          type="switch"
                          id="starred "
                          checked={sound?.starred === 1}
                          onChange={(e) => handleStarred(e, sound?.id)}
                        />
                      </td>
                      <td>
                        <a
                          href={sound.share_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link
                        </a>
                      </td>
                      <td>{sound.current_videos ?? "0"}</td>

                      <td style={{ color: sound?.d_1_color || "black" }}>
                        {sound.d_1_videos_increased ?? 0}
                      </td>
                      <td style={{ color: sound?.d_1_color || "black" }}>
                        {sound.d_1_variation ?? 0}
                      </td>

                      <td style={{ color: sound?.d_2_color || "black" }}>
                        {sound.d_2_videos_increased ?? 0}
                      </td>
                      <td style={{ color: sound?.d_2_color || "black" }}>
                        {sound.d_2_variation ?? 0}
                      </td>

                      <td style={{ color: sound?.d_3_color || "black" }}>
                        {sound.d_3_videos_increased ?? 0}
                      </td>
                      <td style={{ color: sound?.d_3_color || "black" }}>
                        {sound.d_3_variation ?? 0}
                      </td>

                      <td style={{ color: sound?.d_4_color || "black" }}>
                        {sound.d_4_videos_increased ?? 0}
                      </td>
                      <td style={{ color: sound?.d_4_color || "black" }}>
                        {sound.d_4_variation ?? 0}
                      </td>

                      <td style={{ color: sound?.d_5_color || "black" }}>
                        {sound.d_5_videos_increased ?? 0}
                      </td>
                      <td style={{ color: sound?.d_5_color || "black" }}>
                        {sound.d_5_variation ?? 0}
                      </td>

                      <td style={{ color: sound?.d_6_color || "black" }}>
                        {sound.d_6_videos_increased ?? 0}
                      </td>
                      <td style={{ color: sound?.d_6_color || "black" }}>
                        {sound.d_6_variation ?? 0}
                      </td>

                      <td style={{ color: sound?.d_7_color || "black" }}>
                        {sound.d_7_videos_increased ?? 0}
                      </td>
                      <td style={{ color: sound?.d_7_color || "black" }}>
                        {sound.d_7_variation ?? 0}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="18">
                      <NoDataFoundPage />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {sounds.length > 0 && totalPages > 1 && (
              <div className="pagination">
                <PaginationComponent
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  pageStart={pageStart}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TikTokSoundsListing;
