import React, { useEffect, useRef, useState } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonHeader from "../common-components/CommonHeader";
import TikTokStats from "../pages/component/TikTokStats";
import UserCount from "../pages/component/UserCount";
import TopTrendingVideos from "./component/TopTrendingVideos";
import TikTokFollowerGrowth from "./component/TikTokFollowerGrowth";
// import TikTokEngagementMetrics from "./component/TikTokEngagementMetrics";
import TikTokHashtagPerformance from "./component/TikTokHashtagPerformance";
import TikTokIndividualHashtagPerformance from "./component/TikTokIndividualHashtagPerformance";
import TotalVideosMetrix from "./component/TotalVideosMetrix";
import EffectivenessMetrix from "./component/EffectivenessMetrix";
import ViewsAvgLineMetrix from "./component/ViewsAvgLineMetrix";
import InteractionsAvgLineMetrix from "./component/InteractionsAvgLineMetrix";
import { digitsColor, roles } from "../../../utils/constants";
import TikTokSoundStatistics from "./component/TikTokSoundStatistics";
import TikTokFollowersPerDay from "./component/TikTokFollowersPerDay";
import CustomCard from "../common-components/cardWrapper";
import MainCards from "../common-components/main-cards";
import { getMaxHeightByIds } from "../../../utils/helperfunctions";
import axiosInstance from "../../../refershTokenManage/axiosConfig";

const DashboardIndex = () => {
  const role = localStorage.getItem("userRole"),
    hasRole = roles.includes(role),
    hasAdminRole = role === "super-admin" || role === "admin",
    hasClientRole = role === "client",
    [heights, setHeights] = useState({
      firstRow: "",
      secondRow: "",
      thirdRow: "",
      fourthRow: "",
      fivthRow: "",
      sixthRow: "",
    }),
    hasShownToast = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      const firstRow = getMaxHeightByIds("trending-videos", "sound-stats");
      const secondRow = getMaxHeightByIds(
        "follower-per-day",
        "follower-growth"
      );
      const thirdRow = getMaxHeightByIds(
        "hashtag-performance",
        "individual-hashtag-performance"
      );
      const fourthRow = getMaxHeightByIds(
        "videos-per-month",
        "engagement-percentage"
      );
      const fivthRow = getMaxHeightByIds("views-avg", "interaction-avg");
      const sixthRow = getMaxHeightByIds("user-count");

      setHeights({
        firstRow,
        secondRow,
        thirdRow,
        fourthRow,
        fivthRow,
        sixthRow,
      });
    }, 5000);
  }, []);

  const getTikToks = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(`/api/tiktoks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          client_id: Number(localStorage.getItem("client_id")),
        },
      });

      if (response.data?.tiktok.length) {
        if (!hasShownToast.current) {
          toast.success("Dashboard loaded successfully!");
          hasShownToast.current = true;
        }
      } else {
        if (!hasShownToast.current) {
          toast.error(
            "You have not added any TikTok accounts. Set up your first one now!"
          );
          hasShownToast.current = true;
        }
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        if (!hasShownToast.current) {
          toast.error(
            "You have not added any TikTok accounts. Set up your first one now!"
          );
          hasShownToast.current = true;
        }
      }
    }
  };

  useEffect(() => {
    if (hasClientRole) {
      getTikToks();
    }
  }, [hasClientRole]);

  return (
    <div className="userLists">
      <CommonHeader dashboardName={"Dashboard"} />
      {hasRole && (
        <>
          {/* First Row */}
          <div className="row mb-4 mt-4">
            <div className="col-md-12 col-lg-5" id="trending-videos">
              <CustomCard
                header={"Top Trending Videos"}
                style={heights?.firstRow}
                id={"trending-videos-1"}
              >
                <TopTrendingVideos />
              </CustomCard>
            </div>

            <div className="col-md-12 col-lg-7" id="sound-stats">
              <CustomCard
                header={"TikTok Sound Statistics"}
                style={heights?.firstRow}
                id={"sound-stats-1"}
              >
                <TikTokSoundStatistics />
              </CustomCard>
            </div>
          </div>

          {/* Second Row */}
          <div className="row mb-4">
            <div className="col-md-12 col-lg-6" id="follower-per-day">
              <CustomCard
                header={"Followers Per Day"}
                style={heights?.secondRow}
                id={"follower-per-day-1"}
              >
                <TikTokFollowersPerDay />
              </CustomCard>
            </div>

            <div className="col-md-12 col-lg-6" id="follower-growth">
              <CustomCard
                header={"Daily Follower Gain/Loss"}
                style={heights?.secondRow}
                id={"follower-growth-1"}
              >
                <TikTokFollowerGrowth />
              </CustomCard>
            </div>
          </div>
          {/* 3rd Row */}
          <div className="row mb-4">
            <div className="col-md-12 col-lg-6" id="hashtag-performance">
              <CustomCard
                header={"TikTok Hashtag Performance"}
                style={heights?.thirdRow}
                id={"hashtag-performance-1"}
              >
                <TikTokHashtagPerformance />
              </CustomCard>
            </div>

            <div
              className="col-md-12 col-lg-6"
              id="individual-hashtag-performance"
            >
              <CustomCard
                header={"TikTok Individual Hashtag Performance"}
                style={heights?.thirdRow}
                id={"individual-hashtag-performance-1"}
              >
                <TikTokIndividualHashtagPerformance />
              </CustomCard>
            </div>
          </div>

          {/* 4th Row */}
          <div className="row mb-4">
            <div className="col-md-12 col-lg-6" id="video-per-month">
              <CustomCard
                header={"Total Orders Uploaded per Month"}
                style={heights?.fourthRow}
                id={"video-per-month-1"}
              >
                <TotalVideosMetrix />
              </CustomCard>
            </div>

            <div className="col-md-12 col-lg-6" id="engagement-percentage">
              <CustomCard
                header={"Engagement Percentage"}
                style={heights?.fourthRow}
                id={"engagement-percentage-1"}
              >
                <EffectivenessMetrix />
              </CustomCard>
            </div>
          </div>

          {/* 5th Row */}
          <div className="row mb-4">
            <div className="col-md-12 col-lg-6" id="views-avg">
              <CustomCard
                header={"Views Average"}
                style={heights?.fivthRow}
                id={"views-avg-1"}
              >
                <ViewsAvgLineMetrix />
              </CustomCard>
            </div>
            <div className="col-md-12 col-lg-6" id="interaction-avg">
              <CustomCard
                header={"Interactions Average"}
                style={heights?.fivthRow}
                id={"interaction-avg-1"}
              >
                <InteractionsAvgLineMetrix />
              </CustomCard>
            </div>
          </div>

          {/* 6th Row */}
          {hasAdminRole && (
            <div className="row mb-4">
              <div className="col-md-12" id="user-count">
                <CustomCard
                  header={"Total Users and TikTok Accounts"}
                  style={""}
                  id={"user-count-1"}
                >
                  <UserCount />
                </CustomCard>
              </div>
            </div>
          )}
        </>
      )}
      {hasClientRole && (
        <div className="row mb-4">
          <div className="col-md-12" id="tiktok-accounts">
            <CustomCard
              header={"TikTok Accounts Statistics"}
              style={""}
              id={"tiktok-accounts-1"}
            >
              <TikTokStats />
            </CustomCard>
          </div>
          <Toaster position="top-center" richColors />
        </div>
      )}
      <Toaster position="top-center" richColors />
    </div>
  );
};

export default DashboardIndex;
