import React, { useEffect, useState, useContext, useMemo } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import DaysFilter from "./component/DaysFilter";
import moment from "moment";
import subDays from "date-fns/subDays";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ImQuestion } from "react-icons/im";
import { FaExternalLinkAlt, FaLink } from "react-icons/fa";
import NoDataFoundPage from "../common-components/NoDataFound";

const ExternalTikTokSoundsListing = () => {
  const [sounds, setSounds] = useState([]),
    [selectedDays, setSelectedDays] = useState([
      subDays(new Date(), 30 - 1),
      new Date(),
    ]),
    [isLoading, setIsLoading] = useState(false),
    [error, setError] = useState(null),
    [pagination, setPagination] = useState([]),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [sortConfig, setSortConfig] = useState({ key: null, direction: null }),
    role = localStorage.getItem("userRole"),
    hasAdminRole = role === "super-admin" || role === "admin",
    showComingSoon = process.env.REACT_APP_SHOW_COMING_SOON === 'true';

  const fetchSound = async (page = 1) => {
    setIsLoading(true);
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        `/api/tiktok/bobbys/sounds?page=${page}`,
        {
          days: null,
          from: from,
          to: to
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { data, pagination: apiPagination } = response.data;
      if (!apiPagination) {
        console.error("Pagination data missing from the response");
      }
      const reArrange = data.map((i) => ({
        current_videos: i.current_videos,
        id: i.id,
        share_url: i.share_url,
        sound_id: i.sound_id,
        starred: i.starred,
        is_display: i.is_display,
        max_views: i.max_views,
        post_count: i.post_count,
        median_views: i.median_views,
        average_views: i.average_views,
        ...reArrangeObject(i.days),
      }));

      setSounds(reArrange);
      setPagination(apiPagination);
      setCurrentPage(apiPagination.current_page);
      setTotalPages(apiPagination.last_page);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSound(currentPage);
  }, [currentPage, selectedDays]);

  function reArrangeObject(data) {
    if (!typeof data === "object") {
      console.error(
        "days are not an object which are manditory for process sorting"
      );
      return;
    }

    let makeObject = {};
    Object.values(data).forEach((d, i) => {
      makeObject = {
        ...makeObject,
        [`d_${i + 1}_videos_increased`]: d.videos_increased,
        [`d_${i + 1}_variation`]: d.variation,
        [`d_${i + 1}_color`]: d.color,
      };
    });
    return makeObject;
  }

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const sortedSoundsListing = useMemo(() => {
    if (!sortConfig.key) return sounds;
    const sortedData = [...sounds];
    sortedData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  }, [sounds, sortConfig]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return null;
  };

  const handleStarred = async (event, id) => {
    const starred = event.target.checked ? 1 : 0;
    const token = localStorage.getItem("token");
    axiosInstance
      .post(
        `/api/tiktok/attach-starred`,
        {
          sound_id: id,
          starred: starred,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        fetchSound();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleIsDisplay = async (event, id) => {
    const display = event.target.checked ? 1 : 0;
    const token = localStorage.getItem("token");
    axiosInstance
      .post(
        `/api/tiktok/attach-display`,
        {
          sound_id: id,
          is_display: display,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        fetchSound();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFetchExternalSounds = async () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to fetch external sounds?"
    );

    if (!userConfirmed) {
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        "/api/tiktok/fetch-tiktok-external-sounds",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred. Please try again later.";
      console.error(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {showComingSoon ? (
        <div className="clientLists userLists">
          <div class="bgimg">
            <div class="middle">
              <h1 style={{ padding: "0px 10px 0px 10px" }}>Coming Soon</h1>
            </div>
          </div>
        </div >
      ) : (
        <div className="clientLists userLists">
          <Toaster position="top-center" richColors />
          <CommonHeader dashboardName={"Bobby's Favourite Sounds"} />
          <p
            style={{
              color: "red",
              fontSize: "12px",
              marginBottom: "15px",
              marginTop: "15px",
              fontWeight: "bold",
            }}
          >
            Sounds will start to populate after accumulating at least 7 days of data
            scraped from your tracked accounts.
          </p>
          <DaysFilter selectedDays={selectedDays} onChange={onChange} />
          {isLoading && (
            <div className="loader-overlay">
              <CommonLoader />
            </div>
          )}
          <div className="mt-3">
            <div className="userFetchData">
              {
                hasAdminRole && (
                  <>
                    <div className="sync-container">
                      <div className="sync-content">
                        <button
                          disabled={isLoading}
                          className={`sync-button creatrUserSubmit btn btn-primary ${isLoading ? "loading" : ""
                            }`}
                          onClick={handleFetchExternalSounds}
                        >
                          {isLoading ? "Syncing..." : "Sync TikTok External Sounds"}
                        </button>
                      </div>
                    </div>
                  </>
                )
              }
              <div className="userDetails">
                <table className="user-table">
                  <thead>
                    <tr>
                      <th rowSpan="2">ID</th>
                      {hasAdminRole && (
                        <>
                          <th rowSpan="2">Starred</th>
                          <th rowSpan="2">Is Display</th>
                        </>
                      )}
                      <th rowSpan="2">URL</th>
                      <th
                        rowSpan="2"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("current_videos")}
                      >
                        Current # of Videos &nbsp;
                        {getArrow("current_videos")}
                      </th>
                      {/* Top level header for Days */}
                      {Array.from({ length: 5 }, (_, i) => (
                        <th
                          className="text-center font-size-11"
                          key={i}
                          colSpan="2"
                        >
                          Day {i + 1}
                        </th>
                      ))}
                      <th
                        rowSpan="2"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("post_videos")}
                      >
                        Posted # of Videos &nbsp;
                        {getArrow("post_videos")}
                      </th>
                      <th
                        rowSpan="2"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("max_views")}
                      >
                        Max Views &nbsp;
                        {getArrow("max_views")}
                      </th>
                      <th
                        rowSpan="2"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("average_views")}
                      >
                        Average Views &nbsp;
                        {getArrow("average_views")}
                      </th>
                      <th
                        rowSpan="2"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("median_views")}
                      >
                        Median Views &nbsp;
                        {getArrow("median_views")}
                      </th>
                    </tr>
                    <tr>
                      {/* Sub-columns under each Day */}
                      {Array.from({ length: 5 }, (_, i) => (
                        <React.Fragment key={i}>
                          <th
                            className="font-size-11"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSort(`d_${i + 1}_videos_increased`)
                            }
                          >
                            Videos Increased &nbsp;
                            {getArrow(`d_${i + 1}_videos_increased`)}
                          </th>
                          <th
                            className="font-size-11"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSort(`d_${i + 1}_variation`)}
                          >
                            Variation % &nbsp;
                            {getArrow(`d_${i + 1}_variation`)}
                          </th>
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedSoundsListing.length > 0 ? (
                      sortedSoundsListing.map((sound, index) => (
                        <tr key={index}>
                          <td>
                            <Link
                              to={`/Tiktok-sound-statistics/${sound?.sound_id}`}
                            >
                              <span data-tooltip-id="noedit-button">
                                <FaExternalLinkAlt />
                              </span>
                              <ReactTooltip
                                id="noedit-button"
                                place="bottom"
                                content="go to sound statistics"
                              />
                            </Link>
                          </td>
                          {
                            hasAdminRole && (
                              <>
                                <td className="text-center">
                                  <Form.Check
                                    type="switch"
                                    id="starred "
                                    checked={sound?.starred === 1}
                                    onChange={(e) => handleStarred(e, sound?.id)}
                                  />
                                </td>
                                <td className="text-center">
                                  <Form.Check
                                    type="switch"
                                    id="is_display"
                                    checked={sound?.is_display === 1}
                                    onChange={(e) => handleIsDisplay(e, sound?.id)}
                                  />
                                </td>
                              </>
                            )
                          }
                          <td>
                            <a
                              href={sound.share_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Link
                            </a>
                          </td>
                          <td>{sound.current_videos ?? "0"}</td>
                          <td style={{ color: sound?.d_1_color || "black" }}>
                            {sound.d_1_videos_increased ?? 0}
                          </td>
                          <td style={{ color: sound?.d_1_color || "black" }}>
                            {sound.d_1_variation ?? 0}
                          </td>

                          <td style={{ color: sound?.d_2_color || "black" }}>
                            {sound.d_2_videos_increased ?? 0}
                          </td>
                          <td style={{ color: sound?.d_2_color || "black" }}>
                            {sound.d_2_variation ?? 0}
                          </td>

                          <td style={{ color: sound?.d_3_color || "black" }}>
                            {sound.d_3_videos_increased ?? 0}
                          </td>
                          <td style={{ color: sound?.d_3_color || "black" }}>
                            {sound.d_3_variation ?? 0}
                          </td>

                          <td style={{ color: sound?.d_4_color || "black" }}>
                            {sound.d_4_videos_increased ?? 0}
                          </td>
                          <td style={{ color: sound?.d_4_color || "black" }}>
                            {sound.d_4_variation ?? 0}
                          </td>

                          <td style={{ color: sound?.d_5_color || "black" }}>
                            {sound.d_5_videos_increased ?? 0}
                          </td>
                          <td style={{ color: sound?.d_5_color || "black" }}>
                            {sound.d_5_variation ?? 0}
                          </td>
                          <td>{sound.post_count ?? "0"}</td>
                          <td>{sound.max_views ?? "0"}</td>
                          <td>{sound.average_views ?? "0"}</td>
                          <td>{sound.median_views ?? "0"}</td>
                        </tr >
                      ))
                    ) : (
                      <tr>
                        <td colSpan="19">
                          <NoDataFoundPage />
                        </td>
                      </tr>
                    )}
                  </tbody >
                </table >
                {
                  sounds.length > 0 && totalPages > 1 && (
                    <div className="pagination">
                      <PaginationComponent
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageStart={pageStart}
                      />
                    </div>
                  )
                }
              </div >
            </div >
          </div >
        </div >
      )}
    </>
  );
};

export default ExternalTikTokSoundsListing;
