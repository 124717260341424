import { useEffect, useState } from 'react';
import { toast } from "sonner";
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../refershTokenManage/axiosConfig';
import CommonLoader from '../../../loader/CommonLoader';

const TikTokCallback = () => {
    const navigate = useNavigate(),
        [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const handleTikTokLogin = async () => {
            setIsLoading(true);
            const query = new URLSearchParams(window.location.search);
            const code = query.get('code');
            const token = localStorage.getItem("token");
            if (code) {
                try {
                    const response = await axiosInstance.post('/api/tiktok/callback', {
                        code: code,
                        client_id: Number(localStorage.getItem("client_id")),
                    },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setIsLoading(false);
                    navigate('/add-tiktoks', { state: { toastMessage: response?.data?.message } });
                } catch (error) {
                    setIsLoading(false);
                    navigate('/add-tiktoks', { state: { toastMessage: error?.response?.data?.message } });
                }
            }
        };

        handleTikTokLogin();
    }, [navigate]);

    return <div>{
        isLoading && (
            <div className="loader-overlay">
                <CommonLoader />
            </div>
        )
    }</div>;
};

export default TikTokCallback;
