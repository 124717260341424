import React, { useContext } from "react";
import { Modal } from "react-bootstrap";

const CustomModal = ({
  size,
  show,
  handleClose,
  headerContent,
  bodyContent,
  footerContent,
}) => {
  return (
    <Modal size={size && size.trim() ? size : "md"} show={show} onHide={handleClose} >
      <Modal.Header
        style={{ justifyContent: "center !important" }}
        className="border-0 pb-0 text-center justify-content-center"
      >
        {headerContent}
      </Modal.Header>
      <Modal.Body className="d-flex flex-column pt-0 justify-content-center align-items-center">
        {bodyContent}
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-0">
        {footerContent}
      </Modal.Footer>
    </Modal >
  );
};

export default CustomModal;
