import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import CommonLoader from "../../../loader/CommonLoader";
import { TbEdit } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { hasPermission } from "../../../helperPermissionEditor";
import { LoginContext } from "../../../App";
import CustomModal from "../common-components/customModal";
import NoDataFoundPage from "../common-components/NoDataFound";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import { MdDelete } from "react-icons/md";
import GlobalFilter from "./component/GlobalFilter";

const Tags = () => {
  const { user } = useContext(LoginContext),
    [tags, setTags] = useState([]),
    [isEditing, setIsEditing] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [statusOptions, setStatusOptions] = useState([]),
    [record, setRecord] = useState({
      title: "",
      status: "",
    }),
    [show, setShow] = useState(false),
    [perPage, setPerPage] = useState(10),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [deleteTag, setDeleteTag] = useState(false),
    [selectedTagId, setSelectedTagId] = useState(null),
    role = localStorage.getItem("userRole"),
    hasAdminRole = role === "super-admin" || role === "admin",
    [filters, setFilters] = useState({
      search: "",
      date_from: "",
      date_to: "",
      status: "",
    }),
    [filterModal, setFilterModal] = useState(false);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  const handleClose = () => {
    setShow(false);
    setRecord({
      title: "",
      status: "",
    });
    setIsEditing("");
    setFilterModal(false);
  };

  const getListing = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    if (filters) {
      setFilterModal(false);
    }
    const params = new URLSearchParams({
      page,
      ...filters,
      status: String(filters.status)  // Explicitly convert status to string
    }).toString();
    try {
      const response = await axiosInstance.get(`/api/tags?${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTags(response.data.tags);
      setTotalPages(response.data.pagination.last_page);
      setPerPage(response.data.pagination.per_page);
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  useEffect(() => {
    getListing(currentPage);
    const intervalId = setInterval(() => {
      getListing();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [currentPage, filters]);

  const handleEdit = async (row) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(`/api/tags/${row.id}/edit`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      setShow(true);
      if (response.status === 200) {
        const createOptions = Object.values(response.data["status-dropdown"]);

        const statusLocalOptions = [
          { id: 0, name: createOptions[0] },
          { id: 1, name: createOptions[1] },
        ];
        setStatusOptions(statusLocalOptions);

        const { title, status } = response.data.tag;

        setIsEditing(response.data.tag);
        const selectedStatus = status === "Active" ? "1" : "0";
        setRecord({ title, status: selectedStatus });
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching record for edit:", error);
    }
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    const updatedData = {
      title: record.title,
      status: record.status,
    };

    try {
      const response = await axiosInstance.put(
        `/api/tags/${isEditing.id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleClose();
      toast.success("Record updated successfully");
      getListing();

      if (response.status === 200) {
        setIsEditing("");
      } else {
        console.error("Error while updating:", response);
      }
    } catch (error) {
      console.error("Error while updating:", error);
      toast.error(error.response.data.message || "Please try again");
    }
  };

  const handleCreate = async () => {
    const { title } = record;

    // Validate the form fields
    if (!title) {
      toast.error("Please fill in the required field.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      title,
    };

    try {
      await axiosInstance.post(`/api/tags`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      getListing();
      toast.success("Record created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error creating record");
      console.error("Error creating record:", error);
    }
  };

  const handleDelete = async (tagID) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/tags/${tagID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      getListing();
      toast.success("Tag deleted successfully!");
      setDeleteTag(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error deleting Tag");
    } finally {
      setIsLoading(false);
    }
  };

  const getTableUI = () => {
    return (
      <table className="user-table mt-4">
        <thead>
          <tr>
            <th>SR #.</th>
            <th>Title</th>
            <th>Status</th>
            <th>Created By</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {tags?.length > 0 ? (
            tags.map((row, index) => (
              <tr key={row.id}>
                <td>{(currentPage - 1) * perPage + index + 1}</td>
                <td>{row.title}</td>

                <td>
                  <h5>
                    {row.status ? (
                      <Badge
                        bg={`${row.status === "Active" ? "success" : "danger"}`}
                      >
                        {row.status}
                      </Badge>
                    ) : (
                      "--"
                    )}
                  </h5>
                </td>
                <td>{row.created_by}<br />
                  <small>
                    <Badge
                      bg="primary"
                    >
                      {row.created_by_role}
                    </Badge></small></td>
                <td>{row.created_at}</td>
                <td>{row.updated_at}</td>
                <td className="d-flex align-items-center justify-content-center">
                  <span
                    className="px-2 edit me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <TbEdit
                      size={24}
                      data-tooltip-id="editTooltip"
                      data-tooltip-content="Edit Tag"
                      onClick={() => handleEdit(row)}
                      disabled={!hasPermission(user?.permissions, "update-tag")}
                    />
                    <ReactTooltip id="editTooltip" />
                  </span>
                  {hasPermission(
                    user.permissions,
                    "delete-tag"
                  ) && (
                      <button
                        data-tooltip-id="Delete-button"
                        className="delete edit"
                        style={{
                          background: "red",
                          color: "white",
                          fontSize: "22px",
                          padding: "4px 10px",
                        }}
                        onClick={() => {
                          setDeleteTag(true);
                          setSelectedTagId(row.id);
                        }}
                        disabled={record?.deleted_at ? 'disabled' : ''}
                      >
                        <MdDelete />
                      </button>
                    )}
                  <ReactTooltip
                    id="Delete-button"
                    place="bottom"
                    content="Delete Tag"
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center">
                <NoDataFoundPage />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const getFormUI = () => {
    return (
      <div style={{ justifyContent: "center !important" }}>
        <Form.Group className="mb-3" controlId="formText">
          <Form.Control
            type="text"
            placeholder="Enter Tag"
            value={record.title}
            onChange={(e) => setRecord({ ...record, title: e.target.value })}
          />
        </Form.Group>

        {/* show in edit only */}
        {isEditing && (
          <Form.Group className="mb-3" controlId="formClientStatus">
            <Form.Select
              value={record.status}
              onChange={(e) =>
                setRecord({
                  ...record,
                  status: e.target.value,
                })
              }
            >
              <option disabled>Status</option>
              {statusOptions.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
      </div>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Tags"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => setShow(true)}
        user={user}
      />
      <Button
        variant="primary"
        onClick={() => {
          setFilterModal(true);
        }}
        className="mt-3"
      >
        Filter
      </Button>
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
          {totalPages > 1 && (
            <div className="pagination">
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                pageStart={pageStart}
              />
            </div>
          )}
        </div>
      </div>

      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <div>
            <h2 className="create_user_heading">{`${isEditing ? "Update" : "Create"
              } tag`}</h2>
          </div>
        }
        bodyContent={getFormUI()}
        footerContent={
          <div className="d-flex justify-content-center align-items-center">
            <Button
              variant="primary"
              className="creatrUserSubmit me-3"
              onClick={isEditing ? handleUpdate : handleCreate}
            >
              {`${isEditing ? "Update" : "Create"} tag`}
            </Button>
            <Button
              variant="secondary"
              className="creatrUserClose"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />

      {/* Delete Confirmation Modal */}
      <CustomModal
        show={deleteTag}
        handleClose={() => setDeleteTag(false)}
        headerContent={<h4>Confirm Delete</h4>}
        bodyContent={<p>Are you sure you want to delete this Tag?</p>}
        footerContent={
          <>
            <Button
              variant="danger"
              onClick={() => handleDelete(selectedTagId)}
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Confirm"}
            </Button>
            <Button
              variant="secondary"
              className=""
              onClick={() => setDeleteTag(false)}>
              Close
            </Button>
          </>
        }
      />
      {/* Filters Modal */}
      <CustomModal
        show={filterModal}
        handleClose={() => setFilterModal(false)}
        headerContent={<h4>Filters</h4>}
        bodyContent={
          <GlobalFilter
            parentFilters={filters}
            accountManagers={null}
            userRoles={null}
            freelancers={null}
            onFilterChange={handleFilterChange}
            isAdmin={hasAdminRole}
            module={"tags"}
            phoneServices={null}
            typeOptions={null}
            labelOptions={null}
            clientOptions={null}
            bookOptions={null}
            textOptions={null}
          />
        }
        footerContent={<></>}
      />
    </div>
  );
};

export default Tags;
