import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import TikTokAccountFilters from "./component/TikTokAccountFilters";
import { Popover, ArrowContainer } from "react-tiny-popover";
import { LuFilter } from "react-icons/lu";
import { Button } from "react-bootstrap";
import FullscreenWrapper from "../common-components/FullScreenWrapper";
import { colors, backgroundColors } from "../../../utils/constants";
import { Bar } from "react-chartjs-2";
import moment from "moment/moment";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { LoginContext } from "../../../App";
import MainCards from "../common-components/main-cards";
import CustomCard from "../common-components/cardWrapper";
import { truncateString } from "../../../utils/helperfunctions";
import NoDataFoundPage from "../common-components/NoDataFound";
import CustomModal from "../common-components/customModal";

const TikTokIndex = () => {
  const [accounts, setAccounts] = useState([]),
    [isLoading, setLoading] = useState(false),
    [error, setError] = useState(null),
    [pagination, setPagination] = useState([]),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [filters, setFilters] = useState({
      client_id: null,
      follower_count_from: "",
      follower_count_to: "",
      following_count_from: "",
      following_count_to: "",
      total_favorited_from: "",
      total_favorited_to: "",
      visible_videos_count_from: "",
      visible_videos_count_to: "",
      from: "",
      to: "",
      sort_by: "average_views",
      order_by: null,
      column_sort: "",
    }),
    [clients, setClients] = useState([]),
    [record, setRecord] = useState({
      visible: false,
      clients: [],
    }),
    [isPopoverOpen, setIsPopoverOpen] = useState(false),
    clickMeButtonRef = useRef(),
    [sortConfig, setSortConfig] = useState({ key: null, direction: null }),
    [chartsData, setChartData] = useState({
      views: [],
      interactions: [],
      engagements: [],
      names: [],
      fullName: {},
      images: [],
    }),
    { theme, fullScreen } = useContext(LoginContext),
    [filterModal, setFilterModal] = useState(false);

  const fetchTikTok = async (page = 1) => {
    setLoading(true);
    if (filters) {
      setFilterModal(false);
    }
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        `/api/tiktok/accounts?page=${page}`,
        filters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (
        !response.data ||
        !response.data.data ||
        response.data.data.length === 0
      ) {
        setChartData({
          views: [],
          interactions: [],
          engagements: [],
          names: [],
          images: [],
        });
        setAccounts([]);
        setPagination({});
        setCurrentPage(1);
        setTotalPages(1);
        setLoading(false);
        return;
      }
      const localViews = [],
        localInteractions = [],
        localEngagement = [],
        localNames = [],
        localFullName = {};

      const localAccounts = response.data.data.map((account) => {
        const shortLabel = truncateString(account.tiktok_account.nickname, 8);
        localViews.push(account.average_views);
        localInteractions.push(account.average_interactions);
        localEngagement.push(account.average_engagement);
        localNames.push(shortLabel);
        localFullName[shortLabel] = account.tiktok_account.nickname;

        return {
          ...account.tiktok_account,
          views: Number(account.average_views),
          interactions: Number(account.average_interactions),
          engagement: Number(account.average_engagement),
        };
      });
      const images = localAccounts.map((img) => img.avatar);

      setChartData({
        views: localViews,
        interactions: localInteractions,
        engagements: localEngagement,
        fullName: localFullName,
        names: localNames,
        images,
      });

      setAccounts(localAccounts);
      const { pagination: apiPagination } = response.data;
      setPagination(apiPagination);
      setCurrentPage(apiPagination.current_page);
      setTotalPages(apiPagination.last_page);
    } catch (err) {
      setChartData({
        views: [],
        interactions: [],
        engagements: [],
        names: [],
        images: [],
      });
      setAccounts([]);
      setPagination({});
      setCurrentPage(1);
      setTotalPages(1);
      setError("Failed to fetch TikTok Accounts");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTikTok(currentPage);
  }, [currentPage, filters]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(`/api/dashboard/clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const localClients = response.data.clients?.map((client) => ({
          name: client.name,
          id: client.id,
        }));
        setRecord({
          ...record,
          visible: true,
        });
        setClients(localClients);
      }
    } catch (error) {
      console.error("Error fetching record for edit:", error);
    }
  };

  const sortedAccounts = useMemo(() => {
    if (!sortConfig.key) return accounts;

    const sortedData = [...accounts];
    sortedData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  }, [accounts, sortConfig]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setFilters({
      ...filters,
      column_sort: key,
      order_by: direction,
    });
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "▲" : "▼";
    }
    return null;
  };

  // Charts
  const generateChartData = (label, content) => {
    const data = {
      labels: chartsData.names.slice(0, 5),
      datasets: [
        {
          label: label,
          data: content.slice(0, 5),
          backgroundColor: Object.values(colors),
          borderColor: Object.values(backgroundColors),
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: Object.values(colors),
          image: chartsData.images.slice(0, 5),
        },
      ],
    };
    return data;
  };
  function customTooltip(context) {
    let tooltipEl = document.getElementById("chartjs-tooltip");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.background = "#ffffff";
      tooltipEl.style.border = "1px solid #ccc";
      tooltipEl.style.borderRadius = "4px";
      tooltipEl.style.boxShadow = "0 3px 6px rgba(0, 0, 0, 0.15)";
      tooltipEl.style.padding = "10px";
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.transition = "0.2s ease";
      document.body.appendChild(tooltipEl);
    }

    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    if (tooltipModel.body) {
      const tooltipItems = tooltipModel.dataPoints[0];
      const label = tooltipItems.label;
      const value = tooltipItems.raw;
      const fullLabel = chartsData.fullName[label];
      const imageUrl = chartsData.images[tooltipItems.dataIndex]; // Assuming you have an image URL mapped

      tooltipEl.innerHTML = `
        <div style="display: flex; align-items: center;">
          <img src="${imageUrl}" alt="${fullLabel}" style="width: 40px; height: 40px; margin-right: 10px;">
          <div>
            <strong>${fullLabel}</strong><br>
            ${value}
          </div>
        </div>
      `;
    }

    const position = context.chart.canvas.getBoundingClientRect();
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left =
      position.left + window.pageXOffset + tooltipModel.caretX + "px";
    tooltipEl.style.top =
      position.top + window.pageYOffset + tooltipModel.caretY + "px";
  }

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          color: theme === "dark" || fullScreen ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: theme === "dark" || fullScreen ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: customTooltip,
      },
      legend: {
        position: "top",
        labels: {
          color: theme === "dark" || fullScreen ? "#ffffff" : "#000000",
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
  };

  const imagePlugin = {
    id: "imagePlugin",
    afterDatasetsDraw(chart) {
      const { ctx, data } = chart;

      data.datasets[0].image.forEach((image, index) => {
        const meta = chart.getDatasetMeta(0).data[index];

        if (!meta) return;

        const xPos = meta.x - 15;
        const yPos = meta.y - 35;
        const newImage = new Image();
        const radius = 17;

        newImage.src = image;
        newImage.onload = () => {
          ctx.save();
          ctx.beginPath();
          ctx.arc(xPos + radius, yPos + radius, radius, 0, Math.PI * 2);
          ctx.closePath();
          ctx.clip();
          ctx.drawImage(newImage, xPos, yPos, 50, 50);
          ctx.restore();
        };
      });
    },
  };

  return (
    <div className="clientLists userLists">
      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}

      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">
            <div style={{ display: "flex" }}>
              <h1 className="bold fs-4 mb-0 me-4">TikTok Analytics</h1>
              <div>
                <Button
                  variant="primary"
                  onClick={() => {
                    setFilterModal(true);
                  }}
                >
                  Filters
                </Button>
              </div>
            </div>

            {!isLoading && (
              <div className="row mt-3 p-0">
                <div className="col-4">
                  <CustomCard header={"Average Views"} id={"average-views"}>
                    <div className="chart-wrapper" id="average-views">
                      {chartsData?.views?.length > 0 ? (
                        <Bar
                          data={generateChartData(
                            "Average View",
                            chartsData.views
                          )}
                          options={options}
                          plugins={[imagePlugin]}
                        />
                      ) : (
                        <p>No user statistics available.</p>
                      )}
                    </div>
                  </CustomCard>
                </div>
                <div className="col-4">
                  <CustomCard
                    header={"Average Interactions"}
                    id={"average-interactions"}
                  >
                    <div className="chart-wrapper" id="average-interactions">
                      {chartsData?.interactions?.length > 0 ? (
                        <Bar
                          data={generateChartData(
                            "Average Interactions",
                            chartsData.interactions
                          )}
                          options={options}
                          plugins={[imagePlugin]}
                        />
                      ) : (
                        <p>No user statistics available.</p>
                      )}
                    </div>
                  </CustomCard>
                </div>
                <div className="col-4">
                  <CustomCard header={"Engagements %"} id={"engagements"}>
                    <div className="chart-wrapper" id="engagements">
                      {chartsData?.engagements?.length > 0 ? (
                        <Bar
                          data={generateChartData(
                            "Engagement",
                            chartsData.engagements
                          )}
                          options={options}
                          plugins={[imagePlugin]}
                        />
                      ) : (
                        <p>No user statistics available.</p>
                      )}
                    </div>
                  </CustomCard>
                </div>
              </div>
            )}

            <div className="mt-4">
              <table className="user-table">
                <thead>
                  <tr>
                    <th>Avatar</th>
                    <th>Unique ID</th>
                    <th>Nickname</th>
                    <th>Client</th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("follower_count")}
                    >
                      Follower Count &nbsp;
                      {getArrow("follower_count")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("following_count")}
                    >
                      Following Count &nbsp;
                      {getArrow("following_count")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("total_favorited")}
                    >
                      Total Favorited &nbsp;
                      {getArrow("total_favorited")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("visible_videos_count")}
                    >
                      Visible Videos &nbsp;
                      {getArrow("visible_videos_count")}
                    </th>
                    <th>Avg. View</th>
                    <th>Avg. Interactions</th>
                    <th>Engagement</th>
                    <th>Updated At</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.length > 0 ? (
                    accounts.map((account, index) => (
                      <tr key={index}>
                        <td>
                          <img
                            src={account.avatar}
                            alt={account.nickname}
                            width="50"
                            height="50"
                            style={{ borderRadius: "50%" }}
                          />
                        </td>
                        <td>
                          <Link to={`/Tiktok-statistics/${account?.tiktok_id}`}>
                            {account.unique_id}
                          </Link>
                        </td>
                        <td>{account.nickname}</td>
                        <td>{account.client}</td>
                        <td>{account.follower_count}</td>
                        <td>{account.following_count}</td>
                        <td>{account.total_favorited}</td>
                        <td>{account.visible_videos_count}</td>
                        <td>{account.views}</td>
                        <td>{account.interactions}</td>
                        <td>{account.engagement}</td>
                        <td style={{ maxWidth: "100px" }}>
                          {moment(account.updated_at).format("MMM Do YY")}
                        </td>
                        <td>
                          <a
                            href={account.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span data-tooltip-id="noedit-button">
                              <FaExternalLinkAlt />
                            </span>
                            <ReactTooltip
                              id="noedit-button"
                              place="bottom"
                              content="go to View Profile"
                            />
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={14} className="text-center">
                        <NoDataFoundPage />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {accounts.length > 0 && totalPages > 1 && (
              <div className="pagination">
                <PaginationComponent
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  pageStart={pageStart}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Filters Modal */}
      <CustomModal
        size="lg"
        show={filterModal}
        handleClose={() => setFilterModal(false)}
        headerContent={<h4>Filters</h4>}
        bodyContent={
          <TikTokAccountFilters
            parentFilters={filters}
            clients={clients}
            onFilterChange={handleFilterChange}
          />
        }
        footerContent={<></>}
      />
    </div>
  );
};

export default TikTokIndex;
