import React, { useEffect, useState, useContext } from "react";
import { Line } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import YearFilter from "./YearFilter";
import SkeletonLoader from "../../common-components/skeletonLoader";
import ErrorPage from "../../common-components/error";
import FullscreenWrapper from "../../common-components/FullScreenWrapper";
import NoDataFoundPage from "../../common-components/NoDataFound";
import { LoginContext } from "../../../../App";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const ViewsAvgLineMetrix = () => {
  const [selectedYear, setYear] = useState(2025),
    { theme, fullScreen } = useContext(LoginContext),
    [viewsAvgData, setViewsAvgData] = useState([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null),
    [noData, setNoData] = useState(null);

  const fetchViewsAvg = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        "/api/tiktok/views-average",
        {
          year: selectedYear,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setViewsAvgData(response.data.data);
      if (!response.data.data.length) {
        setNoData(true);
      } else {
        setNoData(false);
      }
      setLoading(false);
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 404) {
        setNoData(true);
      }
      setError("Failed to fetch views average data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchViewsAvg();
  }, [selectedYear]);

  const generateChartData = () => {
    const labels = viewsAvgData.map((item) => `${item.month}-${item.year}`);
    const data = {
      labels,
      datasets: [
        {
          label: "Views Avg",
          data: viewsAvgData.map((item) => item.average_views),
          borderColor: "#36A2EB",
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          fill: true,
        },
      ],
    };
    return data;
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          color: theme === "dark" || fullScreen ? "#ffffff" : "#000000",
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme === "dark" || fullScreen ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Month-Year",
        },
      },
      y: {
        ticks: {
          color: theme === "dark" || fullScreen ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Average Views",
        },
        beginAtZero: true,
      },
    },
  };
  return (
    <div className="views-avg-line-graph">
      <div className="filter-container p-3">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <YearFilter selectedYear={selectedYear} onChange={setYear} />
          </div>
        </div>
      </div>
      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : noData ? (
        <NoDataFoundPage />
      ) : error ? (
        <ErrorPage refetch={fetchViewsAvg} />
      ) : (
        <div className="chart-wrapper" id="views-avg-1">
          {viewsAvgData.length > 0 ? (
            <Line data={generateChartData()} options={options} />
          ) : (
            <p>No data available for the selected year.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewsAvgLineMetrix;
