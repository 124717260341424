import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../login.css";
import axiosInstance, {
  initializeTokenRefresh,
} from "../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../App";

import CommonLoader from "../../../loader/CommonLoader";
import ReCAPTCHA from "react-google-recaptcha";

const SignIn = () => {
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [email, setEmail] = useState(""),
    [password, setPassword] = useState(""),
    [loading, setLoading] = useState(false),
    { setCreateUser, setUser, setCreateAccountManager } = useContext(LoginContext),
    navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  let [loginError, setLoginError] = useState("");

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleLogin = async (e) => {
    try {
      if (!recaptchaToken && process.env.REACT_APP_RECAPTCHA_ENABLED == true) {
        e.preventDefault();
        alert("Please complete the reCAPTCHA!");
        return;
      }
      setLoading(true);
      const response = await axiosInstance.post(`/api/login`, {
        email,
        password,
        recaptchaToken,
      });

      const {
        authorization,
        user: { id, name, roles, client },
      } = response.data;

      const token = authorization.token;
      const refreshToken = authorization.refresh_token;
      const permissions = roles.flatMap((role) =>
        role.permissions.map((permission) => permission.name)
      );

      if (roles[0].name === "client") {
        localStorage.setItem("client_id", client.id);
        localStorage.setItem("last_sync_time", client.last_sync_time);
      }
      localStorage.setItem("user_id", id);
      localStorage.setItem("userName", name);
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("userRole", roles[0].name);
      localStorage.setItem("permissions", JSON.stringify(permissions));
      initializeTokenRefresh();
      setUser({ email, isLoggedIn: true, permissions });
      setEmail("");
      setPassword("");
      setLoginError("");
      navigate("/dashboard", { state: { from: "signIn" }, replace: true });
    } catch (error) {
      console.error("Login error:", error);

      if (error.response) {
        const { status, data } = error.response;

        switch (status) {
          case 401:
            loginError = data.message;
            break;
          case 404:
            loginError = data.message;
            break;
          case 500:
            loginError = data.message;
            break;
          default:
            loginError = data.message;
            break;
        }
      } else {
        loginError = "Unable to connect to the server. Please check your network connection.";
      }

      setLoginError(loginError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUser({
        isLoggedIn: localStorage.getItem("token"),
        permissions: JSON.parse(localStorage.getItem("permissions")),
      });
    }
  }, [setUser]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="main signInBackground">
      <div className="arrow arrow--top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="270.11"
          height="649.9"
          overflow="visible"
        >
          <style>
            {`.geo-arrow {
            fill: none;
            stroke: #fff;
            stroke-width: 2;
            stroke-miterlimit: 10;
          }`}
          </style>
          <g className="item-to bounce-1">
            <path
              className="geo-arrow draw-in"
              d="M135.06 142.564L267.995 275.5 135.06 408.434 2.125 275.499z"
            />
          </g>
          <circle
            className="geo-arrow item-to bounce-2"
            cx="194.65"
            cy="69.54"
            r="7.96"
          />
          <circle
            className="geo-arrow draw-in"
            cx="194.65"
            cy="39.5"
            r="7.96"
          />
          <circle
            className="geo-arrow item-to bounce-3"
            cx="194.65"
            cy="9.46"
            r="7.96"
          />
          <g className="geo-arrow item-to bounce-2">
            <path
              className="st0 draw-in"
              d="M181.21 619.5l13.27 27 13.27-27zM194.48 644.5v-552"
            />
          </g>
        </svg>
      </div>
      <div className="arrow arrow--bottom">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31.35"
          height="649.9"
          overflow="visible"
        >
          <style>
            {`.geo-arrow {
            fill: none;
            stroke: #fff;
            stroke-width: 2;
            stroke-miterlimit: 10;
          }`}
          </style>
          <g className="item-to bounce-1">
            <circle
              className="geo-arrow item-to bounce-3"
              cx="15.5"
              cy="580.36"
              r="7.96"
            />
            <circle
              className="geo-arrow draw-in"
              cx="15.5"
              cy="610.4"
              r="7.96"
            />
            <circle
              className="geo-arrow item-to bounce-2"
              cx="15.5"
              cy="640.44"
              r="7.96"
            />
            <g className="item-to bounce-2">
              <path
                className="geo-arrow draw-in"
                d="M28.94 30.4l-13.26-27-13.27 27zM15.68 5.4v552"
              />
            </g>
          </g>
        </svg>
      </div>
      <div className="main__text-wrapper">
        <div className="form p-3 p-md-5">
          <h3 className="mb-2 mb-md-5 fs-1">Sign In</h3>

          <h4 className="mb-5 fs-2 fw-bold heading-color">Login To Your Account</h4>
          <div className="email_login">
            <div className="input_email">
              <input
                type="email"
                id="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                required
                className="loginFields"
              />
            </div>
          </div>
          <div className="password">
            <div className="input_password">
              <input
                type="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
                required
                className="loginFields"
              />
            </div>
          </div>
          {loading && (
            <div className="loader-overlay">
              <CommonLoader />
            </div>
          )}
          {loginError && (
            <div className="error-message text-danger fw-bold fs-5 text-center mt-3">
              {loginError}
            </div>
          )}
          {process.env.REACT_APP_RECAPTCHA_ENABLED === 'true' && (
            <div className="recaptcha-signin">
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={handleRecaptchaChange}
              />
            </div>
          )}
          <button className="submit" onClick={handleLogin} disabled={loading}>
            Sign In
          </button>
          <div className="newuser">
            Don't have an account?
            <span className="account ms-2" onClick={() => setCreateUser(true)}>
              Sign Up
            </span>
          </div>
          {process.env.REACT_APP_ACCOUNT_MANAGER_SIGNUP === 'true' && (
            <div className="newuser">
              Sign up as Account Manager?
              <span className="account ms-2" onClick={() => setCreateAccountManager(true)}>
                Sign Up
              </span>
            </div>
          )}
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="dotted-circle"
          width="352"
          height="352"
          overflow="visible"
        >
          <circle
            cx="176"
            cy="176"
            r="174"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeDasharray="12.921,11.9271"
          />
        </svg>
      </div>
    </div >
  );
};

export default SignIn;
