import React, { useState, useEffect } from "react";

const GlobalFilter = ({ parentFilters, accountManagers, onFilterChange, isAdmin, module, userRoles, freelancers, phoneServices, typeOptions, labelOptions, clientOptions, bookOptions, textOptions }) => {
    const initialFilters = {
        account_manager_id: "",
        search: "",
        email: "",
        date_from: "",
        date_to: "",
        status: "",
        role: "",
        freelancer_id: "",
        label_id: "",
        client_id: "",
        book_id: "",
        text_id: "",
    };

    const [filters, setFilters] = useState(parentFilters || initialFilters);

    useEffect(() => {
        setFilters(parentFilters || initialFilters);
    }, [parentFilters]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters, [name]: value };
            return updatedFilters;
        });
    };

    const applyFilters = () => {
        onFilterChange(filters);
    };

    const resetFilters = () => {
        setFilters({ ...initialFilters });
        onFilterChange({ ...initialFilters });
    };

    return (
        <div className="row mb-3">
            {isAdmin && (module == "clients" || module == "books" || module == "texts") && (
                <div className="col-md-12 mb-3">
                    <>
                        <label>Account Manager</label>
                        <select
                            name="account_manager_id"
                            value={filters.account_manager_id}
                            onChange={handleFilterChange}
                            className="form-select"
                        >
                            <option value="">Select Account Manager</option>
                            {accountManagers?.map((manager) => (
                                <option key={manager.id} value={manager.id}>
                                    {manager.name}
                                </option>
                            ))}
                        </select>
                    </>
                </div>
            )}
            {isAdmin && (module == "books" || module == "texts") && (
                <div className="col-md-12 mb-3">
                    <>
                        <label>Clients</label>
                        <select
                            name="client_id"
                            value={filters.client_id}
                            onChange={handleFilterChange}
                            className="form-select"
                        >
                            <option value="">Select Client</option>
                            {clientOptions?.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </>
                </div>
            )}
            {isAdmin && module == "hashTags" && (
                <div className="col-md-12 mb-3">
                    <>
                        <label>Books</label>
                        <select
                            name="book_id"
                            value={filters.book_id}
                            onChange={handleFilterChange}
                            className="form-select mb-3"
                        >
                            <option value="">Select Book</option>
                            {bookOptions?.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        <label>Texts</label>
                        <select
                            name="text_id"
                            value={filters.text_id}
                            onChange={handleFilterChange}
                            className="form-select"
                        >
                            <option value="">Select Text</option>
                            {textOptions?.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </>
                </div>
            )}
            <div className={`col-md-12 mb-3`}>
                <label>Name</label>
                <input
                    type="text"
                    name="search"
                    value={filters.search}
                    onChange={handleFilterChange}
                    className="form-control"
                />
            </div>
            {module != "phones" && module != "labels" && module != "sounds" && module != "books" && module != "texts" && module != "hashTags" && module != "tags" && module != "companyManagement" && (
                <div className="col-md-12 mb-3">
                    <label>Email</label>
                    <input
                        type="text"
                        name="email"
                        value={filters.email}
                        onChange={handleFilterChange}
                        className="form-control"
                    />
                </div>
            )}
            <div className="col-md-6 mb-3">
                <label>Date From</label>
                <input
                    type="date"
                    name="date_from"
                    value={filters.date_from}
                    onChange={handleFilterChange}
                    className="form-control"
                />
            </div>
            <div className="col-md-6 mb-3">
                <label>Date To</label>
                <input
                    type="date"
                    name="date_to"
                    value={filters.date_to}
                    onChange={handleFilterChange}
                    className="form-control"
                />
            </div>
            {module != "phones" && module != "labels" && (
                <div className="col-md-12 mb-3">
                    <>
                        <label>Status</label>
                        <select
                            name="status"
                            value={filters.status}
                            onChange={handleFilterChange}
                            className="form-select"
                        >
                            <option value="">Select Status</option>
                            <option value="active">Active</option>
                            <option value="in-active">In-Active</option>
                        </select>
                    </>
                </div>
            )}
            {module == "phones" && (
                <div className="col-md-12 mb-3">
                    <>
                        <label>Status</label>
                        <select
                            name="status"
                            value={filters.status}
                            onChange={handleFilterChange}
                            className="form-select"
                        >
                            <option value="">Select Status</option>
                            {phoneServices?.map((service) => (
                                <option key={service.name.split(' ').join('-')} value={service.name.split(' ').join('-')}>{service.name}</option>
                            ))}
                        </select>
                    </>
                </div>
            )}
            {module == "labels" && (
                <div className="col-md-12 mb-3">
                    <>
                        <label>Type</label>
                        <select
                            name="status"
                            value={filters.status}
                            onChange={handleFilterChange}
                            className="form-select"
                        >
                            <option value="">Select Type</option>
                            {typeOptions?.map((option) => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                    </>
                </div>
            )}

            {module == "users" && (
                <div className="col-md-12 mb-3">
                    <>
                        <label>Role</label>
                        <select
                            name="role"
                            value={filters.role}
                            onChange={handleFilterChange}
                            className="form-select"
                        >
                            <option value="">Select Role</option>
                            {userRoles?.map((role) => (
                                <option key={role.id} value={role.name}>
                                    {role.name}
                                </option>
                            ))}
                        </select>
                    </>
                </div>
            )}
            {module == "phones" && (
                <div className="col-md-12 mb-3">
                    <>
                        <label>Freelancer</label>
                        <select
                            name="freelancer_id"
                            value={filters.freelancer_id}
                            onChange={handleFilterChange}
                            className="form-select"
                        >
                            <option value="">Select Freelancer</option>
                            {freelancers?.map((freelancer) => (
                                <option key={freelancer.id} value={freelancer.id}>
                                    {freelancer.name}
                                </option>
                            ))}
                        </select>
                    </>
                </div>
            )}
            {module == "sounds" || module == "texts" && (
                <div className="col-md-12 mb-3">
                    <>
                        <label>Label</label>
                        <select
                            name="label_id"
                            value={filters.label_id}
                            onChange={handleFilterChange}
                            className="form-select"
                        >
                            <option value="">Select Label</option>
                            {labelOptions?.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.title}
                                </option>
                            ))}
                        </select>
                    </>
                </div>
            )}
            <div className="col-md-12 mb-3">
                <button onClick={applyFilters} className="btn btn-primary">
                    Apply Filters
                </button>
                <button onClick={resetFilters} className="btn btn-secondary ms-2">
                    Reset Filters
                </button>
            </div>
        </div >
    );
};

export default GlobalFilter;