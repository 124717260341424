import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CommonLoader from "../../../loader/CommonLoader";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../App";
import { hasPermission } from "../../../helperPermissionEditor";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import CustomModal from "../common-components/customModal";
import { LuEye } from "react-icons/lu";
import NoDataFoundPage from "../common-components/NoDataFound";
import GlobalFilter from "./component/GlobalFilter";
import { Badge } from "react-bootstrap";

const TextListing = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [texts, setTexts] = useState([]),
    [isEditing, setIsEditing] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [clientOptions, setClientOption] = useState([]),
    [accountManagerOptions, setAccountManagerOptions] = useState([]),
    [bookOptions, setBookOptions] = useState([]),
    [labelOptions, setLabelOptions] = useState([]),
    [record, setRecord] = useState({
      name: "",
      text: "",
      bookId: "",
      clientId: "",
      labelId: "",
      accountManagerId: "",
      postType: "",
      status: "",
    }),
    [view, setView] = useState({
      visible: false,
    }),
    [options, setOptions] = useState([]),
    [show, setShow] = useState(false),
    [perPage, setPerPage] = useState(10),
    role = localStorage.getItem("userRole"),
    hasAdminRole = role === "super-admin" || role === "admin",
    [filters, setFilters] = useState({
      search: "",
      date_from: "",
      date_to: "",
      client_id: "",
      status: "",
      account_manager_id: "",
      label_id: "",
    }),
    [filterModal, setFilterModal] = useState(false),
    [statusOptions, setStatusOptions] = useState([]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  const handleClose = () => {
    setShow(false);
    setRecord({
      name: "",
      text: "",
      bookId: "",
      clientId: "",
      labelId: "",
      accountManagerId: "",
      postType: "",
      status: "",
    });
    setIsEditing("");
    setView({});
    setFilterModal(false);
  };
  const navigate = useNavigate();

  const getListing = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    if (filters) {
      setFilterModal(false);
    }
    const params = new URLSearchParams({
      page,
      ...filters,
      status: String(filters.status)  // Explicitly convert status to string
    }).toString();
    try {
      const response = await axiosInstance.get(`/api/texts?${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setTexts(response.data.texts);
      setTotalPages(response.data.pagination.last_page);
      setPerPage(response.data.pagination.per_page);
      const statusDropdown = Object.entries(response.data.status_dropdown).map(([key, value]) => ({
        id: key,
        name: value,
      }));
      setStatusOptions(statusDropdown);
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  useEffect(() => {
    getListing(currentPage);
    handleShow();

    const intervalId = setInterval(() => {
      getListing();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [currentPage, filters]);

  const handleShow = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(`/api/texts/create`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const clientDropDown = response?.data?.clients.map((client) => ({
        id: client.id,
        name: client.name,
      }));

      const accountManagerDropDown = response?.data?.account_manager.map(
        (account_manager) => ({
          id: account_manager.id,
          name: account_manager.name,
        })
      );

      const bookDropDown = response?.data?.book.map((book) => ({
        id: book.id,
        name: book.book_name,
      }));

      const labelDropDown = response?.data?.label.map((label) => ({
        id: label.id,
        title: label.title,
      }));

      setClientOption(clientDropDown);
      setAccountManagerOptions(accountManagerDropDown);
      setBookOptions(bookDropDown);
      setLabelOptions(labelDropDown);

      setOptions(response?.data?.label || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.message || "Error creating text");
      } else {
        console.error("Error creating text:", error);
        toast.error("Failed to create text. Please try again later.");
      }
    }
  };

  const handleEdit = async (row) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(`/api/texts/${row.id}/edit`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      setShow(true);

      const {
        name,
        text,
        book_id,
        client_id,
        label_id,
        account_manager_id,
        post_type,
      } = response.data.text;

      setIsEditing(response.data.text);

      setRecord((prevState) => ({
        ...prevState,
        name,
        text,
        bookId: book_id,
        clientId: client_id,
        labelId: label_id,
        accountManagerId: account_manager_id,
        postType: post_type,
        status: response.data.text.status
      }));
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching record for edit:", error);
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");

    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/texts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTexts(texts.filter((sound) => sound.id !== id));

      toast.success("Record deleted successfully!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        navigate("/");
      } else {
        console.error("Error deleting Record:", error);
        toast.error("Error deleting Record");
      }
    }
    setIsLoading(false);
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    const updatedData = {
      name: record.name,
      text: record.text,
      book_id: record.bookId,
      client_id: record.clientId,
      label_id: record.labelId,
      status: record.status,
      account_manager_id: record.accountManagerId,
      post_type: record.postType,
    };

    try {
      const response = await axiosInstance.put(
        `/api/texts/${isEditing.id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleClose();
      toast.success("Record updated successfully");
      getListing();

      if (response.status === 200) {
        setIsEditing("");
      } else {
        console.error("Error while updating:", response);
      }
    } catch (error) {
      console.error("Error while updating:", error);
      toast.error(error.response.data.message || "Please try again");
    }
  };

  const handleCreate = async () => {
    const {
      name,
      text,
      bookId,
      clientId,
      labelId,
      accountManagerId,
      postType,
    } = record;

    // Validate the form fields
    if (
      !name ||
      !text ||
      !bookId ||
      !clientId ||
      !labelId ||
      !accountManagerId ||
      !postType
    ) {
      toast.error("Please fill in all the fields before submitting.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      name: name,
      text: text,
      book_id: bookId,
      client_id: clientId,
      label_id: labelId,
      status: "1", // Assuming "1" is the default status value
      account_manager_id: accountManagerId,
      post_type: postType,
    };

    try {
      await axiosInstance.post(`/api/texts`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      getListing();
      toast.success("Record created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error creating record");
      console.error("Error creating record:", error);
    }
  };

  const handleView = (record) => {
    setView({
      visible: true,
      ...record,
    });
    setShow(true);
  };

  const getTableUI = () => {
    return (
      <table className="user-table">
        <thead>
          <tr>
            <th>SR #.</th>
            <th>Text</th>
            <th>Description</th>
            <th>Book</th>
            <th>Client</th>
            <th>Label</th>
            <th>Account Manager</th>
            <th>Post Type</th>
            <th>Status</th>
            <th>Created By</th>
            <th>Updated At</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {texts.length > 0 ? (
            texts.map((row, index) => (
              <tr key={row.id}>
                <td>{(currentPage - 1) * perPage + index + 1}</td>
                <td>{row.name}</td>
                <td>{row.text}</td>
                <td>{row.book}</td>
                <td>{row.client}</td>
                <td>{row.label}</td>
                <td>{row.account_manager}</td>
                <td>{row.post_type}</td>
                <td>{row.status}</td>
                <td>{row.created_by}<br />
                  <small>
                    <Badge
                      bg="primary"
                    >
                      {row.created_by_role}
                    </Badge></small></td>
                <td>{row.updated_at}</td>
                <td className="d-flex align-items-center justify-content-center">
                  <Toaster position="top-center" richColors />
                  <button
                    data-tooltip-id="view-button"
                    className="edit me-2"
                    onClick={() => handleView(row)}
                    style={{
                      padding: "4px 10px",
                      fontSize: "22px",
                    }}
                    disabled={!hasPermission(user?.permissions, "view-text")}
                  >
                    <LuEye />
                  </button>
                  <ReactTooltip
                    id="view-button"
                    place="bottom"
                    content="View Text"
                  />

                  <span
                    className="px-2 edit me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <TbEdit
                      size={24}
                      data-tooltip-id="editTooltip"
                      data-tooltip-content="Edit"
                      onClick={() => handleEdit(row)}
                      disabled={
                        !hasPermission(user?.permissions, "update-text")
                      }
                    />
                    <ReactTooltip id="editTooltip" />
                  </span>

                  <span
                    className="px-2"
                    style={{
                      cursor: "pointer",
                      background: "red",
                      color: "white",
                      fontSize: "16px",
                      padding: "8px 10px",
                      borderRadius: "8px",
                    }}
                  >
                    <MdDelete
                      size={24}
                      data-tooltip-id="deleteTooltip"
                      data-tooltip-content="Delete"
                      onClick={() => handleDelete(row.id)}
                      disabled={
                        !hasPermission(user?.permissions, "delete-text")
                      }
                    />
                    <ReactTooltip id="deleteTooltip" />
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12" className="text-center">
                <NoDataFoundPage />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const getFormUI = () => {
    return view?.visible ? (
      <table class="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {[view].map((record, i) => (
            <tr key={i}>
              <td>{record?.id}</td>
              <td>{record.name}</td>
              <td>{record.post_type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div style={{ justifyContent: "center !important" }}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Control
            type="text"
            placeholder="Enter Text"
            value={record.name}
            onChange={(e) => setRecord({ ...record, name: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formText">
          <Form.Control
            type="text"
            placeholder="Enter Description"
            value={record.text}
            onChange={(e) => setRecord({ ...record, text: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBookDropdown">
          <Form.Select
            value={record.bookId}
            onChange={(e) =>
              setRecord({
                ...record,
                bookId: e.target.value,
              })
            }
          >
            <option>Book Options</option>
            {bookOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formClientDropdown">
          <Form.Select
            value={record.clientId}
            onChange={(e) =>
              setRecord({
                ...record,
                clientId: e.target.value,
              })
            }
          >
            <option>Client Options</option>
            {clientOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formAccountManagerDropdown">
          <Form.Select
            value={record.accountManagerId}
            onChange={(e) =>
              setRecord({
                ...record,
                accountManagerId: e.target.value,
              })
            }
          >
            <option>Account Manager Options</option>
            {accountManagerOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formLabelDropdown">
          <Form.Select
            value={record.labelId}
            onChange={(e) =>
              setRecord({
                ...record,
                labelId: e.target.value,
              })
            }
          >
            <option>Label Options</option>
            {labelOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.title}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPostType">
          <Form.Control
            type="text"
            placeholder="Enter Post Type"
            value={record.postType}
            onChange={(e) => setRecord({ ...record, postType: e.target.value })}
          />
        </Form.Group>
        {isEditing && (
          <Form.Group className="mb-3" controlId="formClientStatus">
            <Form.Select
              value={record.status}
              onChange={(e) =>
                setRecord({
                  ...record,
                  status: e.target.value,
                })
              }
            >
              <option>Status</option>
              {statusOptions.map((status, i) => (
                <option key={i} value={status.id}>
                  {status.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
      </div>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Text"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => setShow(true)}
        user={user}
      />
      <Button
        variant="primary"
        onClick={() => {
          setFilterModal(true);
        }}
        className="mt-3"
      >
        Filter
      </Button>
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
          {totalPages > 1 && (
            <div className="pagination">
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                pageStart={pageStart}
              />
            </div>
          )}
        </div>
      </div>

      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <div>
            {view?.visible ? (
              <h2 className="create_user_heading"> Labels List </h2>
            ) : (
              <h2 className="create_user_heading">{`${isEditing ? "Update" : "Create"
                } Text`}</h2>
            )}
          </div>
        }
        bodyContent={getFormUI()}
        footerContent={
          <>
            <div className="d-flex justify-content-center align-items-center">
              {!view?.visible && (
                <Button
                  variant="primary"
                  className="creatrUserSubmit me-3"
                  onClick={isEditing ? handleUpdate : handleCreate}
                >
                  {`${isEditing ? "Update" : "Create"} Text`}
                </Button>
              )}
              <Button
                variant="secondary"
                className="creatrUserClose"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
      {/* Filters Modal */}
      <CustomModal
        show={filterModal}
        handleClose={() => setFilterModal(false)}
        headerContent={<h4>Filters</h4>}
        bodyContent={
          <GlobalFilter
            parentFilters={filters}
            accountManagers={accountManagerOptions}
            userRoles={null}
            freelancers={null}
            onFilterChange={handleFilterChange}
            isAdmin={hasAdminRole}
            module={"texts"}
            phoneServices={null}
            typeOptions={null}
            labelOptions={labelOptions}
            clientOptions={clientOptions}
          />
        }
        footerContent={<></>}
      />
    </div>
  );
};

export default TextListing;
