import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import axios from "axios";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { TbEdit } from "react-icons/tb";
import CommonLoader from "../../../loader/CommonLoader";
import CommonHeader from "../common-components/CommonHeader";
// import MetrixCards from "./userListingMetrix/MetrixCards";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { TbEditOff } from "react-icons/tb";
import { LoginContext } from "../../../App";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { FaShoppingBag, FaUsers, FaListAlt } from "react-icons/fa";
import { hasPermission } from "../../../helperPermissionEditor";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import { MdAccountBalanceWallet } from "react-icons/md";
import CustomModal from "../common-components/customModal";
import NoDataFoundPage from "../common-components/NoDataFound";
import GlobalFilter from "./component/GlobalFilter";

const UserListing = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [users, setUsers] = useState([]),
    [roles, setRoles] = useState([]),
    [status, setStatus] = useState([]),
    [searchQuery, setSearchQuery] = useState(""),
    [pageStart, setPageStart] = useState(1),
    [isEditing, setIsEditing] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [userNotFound, setUserNotFound] = useState([]),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [editedStatus, setEditedStatus] = useState(""),
    [newUser, setNewUser] = useState({
      name: "",
      email: "",
      role: "",
      password: "",
    }),
    [show, setShow] = useState(false),
    [perPage, setPerPage] = useState(20),
    role = localStorage.getItem("userRole"),
    hasAdminRole = role === "super-admin" || role === "admin",
    [filters, setFilters] = useState({
      search: "",
      email: "",
      date_from: "",
      date_to: "",
      status: "",
      role: "",
    }),
    [filterModal, setFilterModal] = useState(false);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  const handleClose = () => {
    setShow(false);
    setIsLoading(false);
    setNewUser({
      name: "",
      email: "",
      role: "",
      password: "",
    });
    setEditedStatus("");
    setFilterModal(false);
  };

  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const fetchUsers = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      if (filters) {
        setFilterModal(false);
      }
      const params = new URLSearchParams({
        page,
        ...filters,
        status: String(filters.status)  // Explicitly convert status to string
      }).toString();
      const response = await axiosInstance.get(`api/users?${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data.users);
      setTotalPages(response.data.pagination.last_page);
      setPerPage(response.data.pagination.per_page);
      if (response?.data?.status === 403) {
        setUserNotFound(response?.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        navigate("/");
        window.location.reload();
      } else {
        console.error("Error fetching users:", error);
        toast.error("Error fetching users");
      }
    }
    setIsLoading(false);
  };

  const fetchRoles = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/roles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const filteredRoles = response.data.roles.filter(
        (role) => !["account-manager", "client", "freelancer"].includes(role.name)
      );

      setRoles(filteredRoles);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUsers(currentPage);
    fetchRoles();
    const intervalId = setInterval(() => {
      fetchUsers();
      fetchRoles();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [currentPage, filters]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const handleEdit = async (record) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(
        `/api/users/${record?.id}/edit`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      const { user, userRoles, roles } = response.data;

      setShow(true);
      setIsEditing(user);
      setEditedStatus(user.status);
      setNewUser((prevState) => ({
        ...prevState,
        name: user.name,
        email: user.email,
        role: userRoles[Object.values(userRoles)],
      }));
    } catch (e) { }
  };

  const handleSave = async (index) => {
    const token = localStorage.getItem("token");
    const updatedUserData = {
      name: newUser.name,
      email: newUser.email,
      roles: newUser.role,
      status: editedStatus,
    };

    try {
      const response = await axiosInstance.put(
        `/api/users/${isEditing.id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        setIsEditing("");

        toast.success("User updated successfully");
        handleClose();
        fetchUsers();
      } else {
        console.error("Error updating user:", response);
        toast.error("Failed to update user");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to update user");
      console.error("Error updating user:", error);
    }
  };

  const handleClearCache = async () => {
    try {
      await axiosInstance.get(`/api/clear-cached-permissions`);
      toast.success("Cache cleared successfully!");
    } catch (error) {
      console.error("Error clearing cache:", error);
      toast.error("Failed to clear cache. Please try again later.");
    }
  };

  const handleSubmitUser = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const token = localStorage.getItem("token");
    const userPayload = {
      ...newUser,
      roles: `${[newUser?.role]}`,
    };
    delete userPayload.role;
    try {
      const response = await axiosInstance.post(`api/users`, userPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        toast.success("user created successfully");
        setIsLoading(false);
        fetchUsers();
        setNewUser({
          name: "",
          email: "",
          role: "",
          password: "",
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const filteredUsers =
    users?.filter(
      (user) =>
        user?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
    ) ?? [];

  const getTableUI = () => {
    return (
      <table className="user-table">
        <thead>
          <tr>
            <th>SR #.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="7">Loading...</td>
            </tr>
          ) : filteredUsers.length > 0 ? (
            filteredUsers.map((userList, index) => (
              <tr
                key={userList?.id}
                style={{
                  backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                }}
              >
                <td>{(currentPage - 1) * perPage + index + 1}</td>
                <td>{userList?.name}</td>
                <td>{userList?.email}</td>
                <td>{userList?.role}</td>
                <td>{userList?.status}</td>
                <td>{userList?.created_at}</td>
                <td>
                  {userList?.name === "Super Admin" ? (
                    <>
                      <span className="noedit " data-tooltip-id="noedit-button">
                        <TbEditOff />
                      </span>
                      <ReactTooltip
                        id="noedit-button"
                        place="bottom"
                        content="Admin cannot Edit"
                      />
                    </>
                  ) : (
                    <>
                      <button
                        data-tooltip-id="Edit-button"
                        className="edit"
                        onClick={
                          hasPermission(user?.permissions, "update-user")
                            ? () => handleEdit(userList)
                            : () => { }
                        }
                      >
                        {hasPermission(user?.permissions, "update-user") ? (
                          <TbEdit />
                        ) : (
                          <TbEditOff />
                        )}
                      </button>
                      <ReactTooltip
                        id="Edit-button"
                        place="bottom"
                        content="Edit User"
                      />
                    </>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">
                <NoDataFoundPage />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const getFormUI = () => {
    return (
      <div style={{ justifyContent: "center !important" }}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Control
            type="text"
            placeholder="Enter Your Name "
            autoFocus
            value={newUser?.name}
            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Control
            type="email"
            className="creatuserListing"
            placeholder="name@example.com"
            autoFocus
            value={newUser?.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <select
            name="role"
            className="rolelistingDropdown creatuserListing form-select"
            value={newUser?.role}
            onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
            required
          >
            <option disabled value="">
              Select Role
            </option>
            {roles &&
              roles.map((role) => (
                <option key={role?.id} value={role?.name}>
                  {role?.name}
                </option>
              ))}
          </select>
        </Form.Group>
        {isEditing && (
          <Form.Group className="mb-3">
            <select
              name="status"
              className="rolelistingDropdown creatuserListing form-select"
              value={editedStatus}
              onChange={(e) => setEditedStatus(e.target.value)}
            >
              <option disabled value="">
                Select Status
              </option>
              <option key={1} value="1">
                Active
              </option>
              <option key={0} value="0">
                In-Active
              </option>
            </select>
          </Form.Group>
        )}
        {!isEditing && (
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="password"
              className="creatuserListing"
              autoFocus
              value={newUser?.password}
              onChange={(e) =>
                setNewUser({ ...newUser, password: e.target.value })
              }
              placeholder="Password"
            />
          </Form.Group>
        )}
      </div>
    );
  };

  return (
    <div className="userLists">
      {userNotFound.status === 403 ? (
        <div
          style={{
            fontSize: "30px",
            position: "fixed",
            top: "50%",
            left: "60vh",
          }}
          className="user-not-found-message container"
        >
          {userNotFound.message}
        </div>
      ) : (
        <>
          <div className="commonuserComponents">
            <CommonHeader
              dashboardName={"User"}
              searchQuery={searchQuery}
              onSearchChange={setSearchQuery}
              handleShow={handleShow}
              user={user}
            />
            <Button
              variant="primary"
              onClick={() => {
                setFilterModal(true);
              }}
              className="mt-3"
            >
              Filter
            </Button>
          </div>

          {/* {getCards()} */}

          <div className="mt-1">
            <div className="userFetchData">
              <div className="userDetails mt-2">
                <div
                  style={{ backgroundColor: "#fff" }}
                  className="d-flex rounded-2 nav align-content-center"
                ></div>
                {getTableUI()}
              </div>

              {totalPages > 1 && (
                <div className="pagination">
                  <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    pageStart={pageStart}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <div style={{ justifyContent: "center !important" }}>
            <h2 className="create_user_heading">
              {`${isEditing ? "Edit" : "Create"} User`}
            </h2>
            <p className=" fs-4 mt-3 text-muted">
              {`${isEditing ? "Edit" : "Create"
                } a User to manage and view your profile`}
            </p>
          </div>
        }
        bodyContent={getFormUI()}
        footerContent={
          <>
            <Toaster richColors position="top-center" />
            <Button
              variant="primary"
              className="creatrUserSubmit"
              onClick={isEditing ? handleSave : handleSubmitUser}
            >
              {`${isEditing ? "Update" : "Create"}`} User
            </Button>
            <Button
              variant="secondary"
              className="creatrUserClose"
              onClick={handleClose}
            >
              Close
            </Button>
          </>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      {/* Filters Modal */}
      <CustomModal
        show={filterModal}
        handleClose={() => setFilterModal(false)}
        headerContent={<h4>Filters</h4>}
        bodyContent={
          <GlobalFilter
            parentFilters={filters}
            accountManagers={null}
            userRoles={roles}
            onFilterChange={handleFilterChange}
            isAdmin={hasAdminRole}
            module={"users"} />
        }
        footerContent={<></>}
      />
    </div>
  );
};

export default UserListing;
